import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Container } from '../../components/Container';
import { PageTitle } from '../../components/PageTitle';
import {
  gdrpPrivacyPolicyPdf,
  privacyPolicyPdf,
  termOfUsePdf,
} from '../../helpers/publicAssetLinks';

import { DocumentRow } from './DocumentRow';

export function NoticesPage() {
  const { t } = useTranslation();
  return (
    <Container>
      <PageTitle>
        {t('pages.notices.title', 'Agreements and Notices')}
      </PageTitle>
      <TableContainer>
        <Table
          sx={{
            maxWidth: '800px',
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Document Name</strong>
              </TableCell>
              <TableCell>
                <strong>Status</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <DocumentRow
              href={termOfUsePdf}
              name={t('common.labels.termOfUse')}
              status={t(
                'pages.notices.status.notRequired',
                'No Action Required',
              )}
            />
            <DocumentRow
              href={privacyPolicyPdf}
              name={t('common.labels.privacyPolicy')}
              status={t('pages.notices.status.notRequired')}
            />
            <DocumentRow
              href={gdrpPrivacyPolicyPdf}
              name={t('common.labels.gdrPrivacyPolicy', 'GDPR Privacy Policy')}
              status={t('pages.notices.status.notRequired')}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
