import { z } from 'zod';

import i18n from '../../config/i18n';

export function getLoginPageSchema() {
  return z.object({
    email: z
      .string()
      .min(1, i18n.t('validation.email.required'))
      .email(i18n.t('validation.email.invalid')),
    password: z.string().min(1, i18n.t('validation.password.required')),
  });
}

export type ILoginPageSchema = z.infer<ReturnType<typeof getLoginPageSchema>>;
