import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Typography } from '@mui/material';
import { addDays, addYears, subDays } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePickerFromTo } from '../../DatePickers';
import { IDateSelectorContainerProps } from '../../QuoteCalculatorScreens/interfaces';

import { MAX_YEARS_OUT_TO_QUOTE, MIN_EXECUTION_DAYS_OUT } from './constants';
import { getXSRDateRangeSchema } from './getXSRDateRangeSchema';

export function XSRDateRangeSelector({
  initialValues,
  updateInput,
}: IDateSelectorContainerProps) {
  const { t } = useTranslation();

  const currentDate = useMemo(() => new Date(), []);
  const maxDate = useMemo(
    () => addYears(currentDate, MAX_YEARS_OUT_TO_QUOTE),
    [currentDate],
  );
  const minDate = useMemo(
    () => addDays(currentDate, MIN_EXECUTION_DAYS_OUT),
    [currentDate],
  );

  const { control, handleSubmit } = useForm({
    defaultValues: {
      dateRange: [
        initialValues?.[0] ? new Date(initialValues[0]) : minDate,
        initialValues?.[1] ? new Date(initialValues[1]) : minDate,
      ],
    },
    resolver: zodResolver(getXSRDateRangeSchema()),
  });

  const onCloseHandler = useCallback(
    (data) => {
      const [startDate, endDate] = data.dateRange;
      const tradeExecutionDate = subDays(startDate, MIN_EXECUTION_DAYS_OUT);

      updateInput([
        startDate.toISOString(),
        endDate.toISOString(),
        tradeExecutionDate.toISOString(),
      ]);
    },
    [updateInput],
  );

  // send invalid input to to parent 'SelectRiskPeriod' component
  // to disable next button.
  const onInvalidHandler = useCallback(() => {
    updateInput(undefined);
  }, [updateInput]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box textAlign="center" margin="auto" maxWidth={700}>
        <Typography fontWeight={400} mb={2} variant="h4">
          {t(
            'calculator.selectRiskPeriod.xsrSubTitle',
            'Choose the Start date and End date of the risk period.',
          )}
        </Typography>
        <Typography color="common.darkGrey" lineHeight="2rem" variant="body1">
          {t(
            'calculator.selectRiskPeriod.description',
            'The selected Risk Period cannot be shorter than 30 days or longer than 11 months. Trade Execution Requests must be submitted at least 20 days ahead of the Start Date of the Risk Period.',
          )}
        </Typography>
      </Box>
      <Box component="form" mt={3}>
        <DatePickerFromTo
          control={control}
          maxDate={maxDate}
          minDate={minDate}
          name="dateRange"
          onClose={handleSubmit(onCloseHandler, onInvalidHandler)}
        />
      </Box>
    </Box>
  );
}
