import { Box } from '@mui/material';
import { GridCallbackDetails, GridSortModel } from '@mui/x-data-grid';
import { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '../../components/Container';
import { DataGrid } from '../../components/DataGrid';
import { PageTitle } from '../../components/PageTitle';
import { SearchToolbar } from '../../components/SearchToolBar';

import { quoteColumns, QuoteTableRowFormat } from './helpers';

interface IProps {
  handleSearch: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  isLoading?: boolean;
  handleSortModelChange?: (
    model: GridSortModel,
    details: GridCallbackDetails,
  ) => void;
  onPageChange?: (page: number, details: GridCallbackDetails) => void;
  page?: number;
  pageSize?: number;
  quotes: QuoteTableRowFormat;
  rowCount: number;
  search: string;
}

export function QuotesPage({
  handleSearch,
  handleSortModelChange,
  isLoading,
  onPageChange,
  page,
  pageSize,
  quotes,
  rowCount,
  search,
}: IProps) {
  const { t } = useTranslation();

  return (
    <Container minHeight={700}>
      <Box>
        <PageTitle
          rightSlot={<SearchToolbar onChange={handleSearch} value={search} />}
        >
          {t('pages.quotes.title', 'Saved Quotes')}
        </PageTitle>
        <DataGrid
          columns={quoteColumns}
          handleSortModelChange={handleSortModelChange}
          isLoading={isLoading}
          onPageChange={onPageChange}
          page={page}
          pageSize={pageSize}
          rowCount={rowCount}
          rows={quotes}
        />
      </Box>
    </Container>
  );
}
