import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from '../../Button';
import { ModalBase } from '../ModalBase';

interface IProps {
  isLoading?: boolean;
  onClose: () => void;
  onResend: () => void;
  open: boolean;
  sent?: boolean;
}

export function ResendEmailConfirmationModal({
  isLoading,
  onClose,
  onResend,
  open,
  sent,
}: IProps) {
  const { t } = useTranslation();

  return (
    <ModalBase
      open={open}
      onClose={onClose}
      title={t(
        'modals.resendEmailConfirmation.title',
        'Please verify your email',
      )}
    >
      <Box p={3}>
        <Typography>
          {!sent &&
            t(
              'modals.resendEmailConfirmation.message',
              "Didn't receive the email? Please click the button below to resend.",
            )}
          {sent &&
            t(
              'modals.resendEmailConfirmation.resentMessage',
              'Verification email has been resent.',
            )}
        </Typography>
        {!sent && (
          <Button onClick={onResend} disabled={isLoading} sx={{ mt: 2 }}>
            {t('modals.resendEmailConfirmation.button', 'Resend')}
          </Button>
        )}
      </Box>
    </ModalBase>
  );
}
