import SearchIcon from '@mui/icons-material/Search';
import { Box, TextField } from '@mui/material';
import { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { theme } from '../../config';

interface ISearchToolbarProps {
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  value: string;
}

export function SearchToolbar(props: ISearchToolbarProps) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: 1,
      }}
    >
      <TextField
        variant="outlined"
        size="small"
        sx={{
          backgroundColor: theme.palette.common.white,
        }}
        value={props.value}
        onChange={props.onChange}
        placeholder={t('common.actions.search', 'Search')}
        InputProps={{
          endAdornment: <SearchIcon fontSize="small" />,
        }}
      />
    </Box>
  );
}
