export const config = {
  api: {
    grapqlEndpoint:
      process.env.REACT_APP_GRAPHQL_ENDPOINT || 'http://localhost:4000/graphql',
  },
  contact: {
    email: process.env.REACT_APP_CONTACT_EMAIL || 'sales@arbolmarket.com',
  },
  map: {
    attribution:
      '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
    bingMapAPIKey:
      process.env.REACT_APP_BING_API_KEY || 'NEED_TO_SET_BING_API_KEY',
    bingMapBaseUrl: 'https://dev.virtualearth.net/REST/v1',
    streetViewId: 'mapbox/streets-v11',
    satelliteViewId: 'mapbox/satellite-streets-v11',
    mapboxAccessToken:
      process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ||
      'NEED_TO_SET_MAPBOX_ACCESS_TOKEN',
    mapboxUrl:
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
  },
  mui: {
    licenseKey: process.env.REACT_APP_MUI_X_KEY || '',
  },
};
