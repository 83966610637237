import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from '../../Button';
import { ModalBase } from '../ModalBase';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export function PasswordResetSuccessModal({ open, onClose }: IProps) {
  const { t } = useTranslation();

  return (
    <ModalBase
      open={open}
      onClose={onClose}
      title={t('modals.passwordResetSuccess.title', 'Password updated!')}
    >
      <Stack p={3} spacing={4}>
        <Typography>
          {t(
            'modals.passwordResetSuccess.body',
            'Password for your account has been successfully updated.',
          )}
        </Typography>
        <Button onClick={onClose}>{t('common.actions.dismiss')}</Button>
      </Stack>
    </ModalBase>
  );
}
