import { Box, Modal, ModalProps, Typography } from '@mui/material';

interface IProps extends ModalProps {
  title?: string;
}

export function ModalBase({ children, open, title, ...rest }: IProps) {
  return (
    <Modal
      data-testid="modal-base"
      open={open}
      {...rest}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'common.white',
          borderRadius: '4px',
          maxWidth: '400px',
          padding: 3,
          position: 'relative',
          textAlign: 'center',
          top: '-10%',
          width: '100%',
        }}
      >
        <Box
          sx={{
            borderBottom: '1px solid',
            borderColor: 'common.grey',
            mb: 2,
            pb: 1,
          }}
        >
          <Typography variant="h4" component="p">
            {title}
          </Typography>
        </Box>
        {children}
      </Box>
    </Modal>
  );
}
