import { Box } from '@mui/material';
import {
  DataGrid as MUIDataGrid,
  DataGridProps,
  GridCallbackDetails,
  GridColDef,
  GridSortModel,
} from '@mui/x-data-grid';

interface IProps<T> extends DataGridProps {
  columns: GridColDef[];
  isLoading?: boolean;
  handleSortModelChange?: (
    model: GridSortModel,
    details: GridCallbackDetails,
  ) => void;
  onPageChange?: (page: number, details: GridCallbackDetails) => void;
  page?: number;
  pageSize?: number;
  rowCount: number;
  rows: Array<T>;
}

export function DataGrid<T>({
  columns,
  handleSortModelChange,
  isLoading,
  onPageChange,
  page,
  pageSize,
  rowCount,
  rows,
  ...rest
}: IProps<T>) {
  return (
    <Box sx={{ width: '100%' }}>
      <MUIDataGrid
        {...rest}
        autoHeight
        columns={columns}
        disableColumnMenu
        disableSelectionOnClick
        loading={isLoading}
        onPageChange={onPageChange}
        onSortModelChange={handleSortModelChange}
        page={page}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        rowCount={rowCount}
        rows={rows}
        sortingMode="server"
      />
    </Box>
  );
}
