/* eslint-disable @typescript-eslint/no-explicit-any, import/no-named-as-default */
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: any;
  JSON: any;
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Agreements = {
  __typename?: 'Agreements';
  agreeClaimsClient?: Maybe<Scalars['Boolean']>;
  agreeElectronicBroker?: Maybe<Scalars['Boolean']>;
  agreeElectronicClient?: Maybe<Scalars['Boolean']>;
  agreeTerrorismDisclosure?: Maybe<Scalars['Boolean']>;
  signNameBroker?: Maybe<Scalars['String']>;
  signNameCustomer?: Maybe<Scalars['String']>;
};

export enum AppType {
  Default = 'DEFAULT',
  Rm = 'RM',
  Singlife = 'SINGLIFE',
}

export type Application = {
  __typename?: 'Application';
  agentInformation?: Maybe<ContractUserInformation>;
  agreements?: Maybe<Agreements>;
  approvalsObj?: Maybe<ApprovalsObj>;
  clientInformation?: Maybe<ContractUserInformation>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum ApplicationStatus {
  Approved = 'APPROVED',
  AwaitingAgent = 'AWAITING_AGENT',
  AwaitingClient = 'AWAITING_CLIENT',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  PendingApproval = 'PENDING_APPROVAL',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED',
}

export enum ApplicationType {
  Derivatives = 'DERIVATIVES',
  Energy = 'ENERGY',
  Insurance = 'INSURANCE',
}

export type ApprovalsObj = {
  __typename?: 'ApprovalsObj';
  businessType?: Maybe<Scalars['Boolean']>;
  closingDate?: Maybe<Scalars['Boolean']>;
  contractLimit?: Maybe<Scalars['Boolean']>;
  programAggregateLimit?: Maybe<Scalars['Boolean']>;
  stateAndCountyMatch?: Maybe<Scalars['Boolean']>;
};

export type AuthUserPayload = {
  __typename?: 'AuthUserPayload';
  token: Scalars['String'];
  user: User;
};

export type Contract = {
  __typename?: 'Contract';
  _id?: Maybe<Scalars['ID']>;
  application?: Maybe<Application>;
  customerCounterparty?: Maybe<User>;
  lifecycleStage?: Maybe<Scalars['String']>;
  quotes?: Maybe<Array<Maybe<Quote>>>;
  salesAgent?: Maybe<User>;
};

export type ContractUserAddress = {
  __typename?: 'ContractUserAddress';
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type ContractUserInformation = {
  __typename?: 'ContractUserInformation';
  address?: Maybe<ContractUserAddress>;
  email?: Maybe<Scalars['EmailAddress']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type CoverageStructure = {
  __typename?: 'CoverageStructure';
  dataset?: Maybe<Dataset>;
  endDate?: Maybe<Scalars['DateTime']>;
  exhaust?: Maybe<UnitVectorFloat>;
  lcRequired?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<UnitVectorInt>;
  location?: Maybe<Location>;
  programName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  strike?: Maybe<UnitVectorFloat>;
};

export type CreateClientUserInputs = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type CreateQuoteInputs = {
  collateral?: InputMaybe<Scalars['Int']>;
  coverages?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  dataset: Scalars['String'];
  end: Scalars['String'];
  exhaust?: InputMaybe<Scalars['Float']>;
  lat: Scalars['Float'];
  lc_end?: InputMaybe<Scalars['String']>;
  lc_start?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  locationName: Scalars['String'];
  lon: Scalars['Float'];
  option_type?: InputMaybe<OptionType>;
  start: Scalars['String'];
  strike?: InputMaybe<Scalars['Float']>;
  units: Scalars['String'];
};

export type Dataset = {
  __typename?: 'Dataset';
  dclimateId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Feature = {
  __typename?: 'Feature';
  geometry?: Maybe<Geometry>;
  type?: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  _id?: Maybe<Scalars['ID']>;
  bucketName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
};

export type FileStorageInputs = {
  bucketName: S3Bucket;
  folder?: InputMaybe<Scalars['String']>;
};

export type Geometry = {
  __typename?: 'Geometry';
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  properties?: Maybe<Properties>;
  type?: Maybe<Scalars['String']>;
};

export enum GeometryType {
  Point = 'POINT',
  Polygon = 'POLYGON',
}

export enum LifecycleStage {
  AwaitingApplication = 'AWAITING_APPLICATION',
  AwaitingEvaluation = 'AWAITING_EVALUATION',
  Cancelled = 'CANCELLED',
  Evaluated = 'EVALUATED',
  InProgress = 'IN_PROGRESS',
  PolicyIssued = 'POLICY_ISSUED',
  PreApplication = 'PRE_APPLICATION',
}

export type Location = {
  __typename?: 'Location';
  feature?: Maybe<Feature>;
  name?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAppPermission?: Maybe<User>;
  attachClientToQuote?: Maybe<Quote>;
  authorize?: Maybe<AuthUserPayload>;
  changePassword?: Maybe<AuthUserPayload>;
  confirmUser?: Maybe<AuthUserPayload>;
  createApplication?: Maybe<Contract>;
  createClientUser?: Maybe<User>;
  createQuote?: Maybe<Array<Maybe<Quote>>>;
  deleteQuote?: Maybe<Quote>;
  forgotPassword?: Maybe<Scalars['Boolean']>;
  getSignedUrl: Scalars['String'];
  login?: Maybe<AuthUserPayload>;
  registerUser?: Maybe<Scalars['Boolean']>;
  resendConfirm?: Maybe<Scalars['Boolean']>;
  resetPassword?: Maybe<AuthUserPayload>;
  saveQuote?: Maybe<Quote>;
  updateClientEmail?: Maybe<User>;
  updateUser?: Maybe<User>;
  uploadFile: File;
};

export type MutationAddAppPermissionArgs = {
  appType: AppType;
  user: Scalars['String'];
};

export type MutationAttachClientToQuoteArgs = {
  client: Scalars['String'];
  quote: Scalars['String'];
};

export type MutationAuthorizeArgs = {
  token: Scalars['String'];
};

export type MutationChangePasswordArgs = {
  appType?: AppType;
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type MutationConfirmUserArgs = {
  token: Scalars['String'];
};

export type MutationCreateApplicationArgs = {
  client: Scalars['String'];
  quotes: Array<Scalars['String']>;
};

export type MutationCreateClientUserArgs = {
  input: CreateClientUserInputs;
};

export type MutationCreateQuoteArgs = {
  input: CreateQuoteInputs;
  programType: ProgramType;
};

export type MutationDeleteQuoteArgs = {
  quote: Scalars['String'];
};

export type MutationForgotPasswordArgs = {
  appType?: AppType;
  email: Scalars['EmailAddress'];
};

export type MutationGetSignedUrlArgs = {
  file: Scalars['String'];
};

export type MutationLoginArgs = {
  appType?: AppType;
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
};

export type MutationRegisterUserArgs = {
  appType?: AppType;
  input: RegisterUserInputs;
};

export type MutationResendConfirmArgs = {
  appType?: AppType;
};

export type MutationResetPasswordArgs = {
  appType?: AppType;
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationSaveQuoteArgs = {
  client?: InputMaybe<Scalars['String']>;
  quote: Scalars['String'];
};

export type MutationUpdateClientEmailArgs = {
  client: Scalars['String'];
  email: Scalars['EmailAddress'];
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInputs;
};

export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
  input: FileStorageInputs;
};

export enum OptionType {
  Call = 'CALL',
  Put = 'PUT',
}

export type PaginatedQuotePayload = {
  __typename?: 'PaginatedQuotePayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Quote>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PaginationInputs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type Prices = {
  __typename?: 'Prices';
  agentCommission?: Maybe<UnitVectorFloat>;
  arbolCommission?: Maybe<UnitVectorFloat>;
  customerPremium?: Maybe<UnitVectorFloat>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  riskCapitalPremium?: Maybe<UnitVectorFloat>;
};

export enum ProgramType {
  CustomExcessRain = 'CUSTOM_EXCESS_RAIN',
  CustomLowRain = 'CUSTOM_LOW_RAIN',
  Grp = 'GRP',
  Xsr = 'XSR',
}

export type Properties = {
  __typename?: 'Properties';
  name?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  currentUser?: Maybe<User>;
  getGRPDates: Array<RiskPeriodDateBlock>;
  getGatewayMetaData?: Maybe<Scalars['JSON']>;
  getQuotes?: Maybe<PaginatedQuotePayload>;
  getRainfallDataByLocations: Array<RainfallDataByLocation>;
  getUsers?: Maybe<Array<Maybe<User>>>;
};

export type QueryGetGatewayMetaDataArgs = {
  dataset: Scalars['String'];
};

export type QueryGetQuotesArgs = {
  input?: InputMaybe<PaginationInputs>;
};

export type QueryGetRainfallDataByLocationsArgs = {
  asOf?: InputMaybe<Scalars['DateTime']>;
  input: Array<RainfallDataByLocationInput>;
};

export type Quote = {
  __typename?: 'Quote';
  _id?: Maybe<Scalars['ID']>;
  coverageStructure?: Maybe<CoverageStructure>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customerCounterparty?: Maybe<User>;
  prices?: Maybe<Prices>;
  requestId?: Maybe<Scalars['String']>;
  salesAgent?: Maybe<User>;
  salesPlatform?: Maybe<SalesPlatform>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RainfallDataByLocation = {
  __typename?: 'RainfallDataByLocation';
  city: Scalars['String'];
  country: Scalars['String'];
  date: Scalars['String'];
  location: Array<Scalars['Float']>;
  rainfall: Scalars['Float'];
  unit: Scalars['String'];
};

export type RainfallDataByLocationInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  location: Array<Scalars['Float']>;
  startDate: Scalars['DateTime'];
};

export type RegisterUserInputs = {
  activationCode?: InputMaybe<Scalars['String']>;
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  companyName?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type RiskPeriodDateBlock = {
  __typename?: 'RiskPeriodDateBlock';
  deadlineDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export enum Role {
  Admin = 'admin',
  Agent = 'agent',
  Client = 'client',
}

export enum S3Bucket {
  GeneralFiles = 'GENERAL_FILES',
}

export type SalesPlatform = {
  __typename?: 'SalesPlatform';
  index?: Maybe<UnitHistory>;
  payouts?: Maybe<UnitHistory>;
  relativeStrike?: Maybe<Scalars['Float']>;
};

export type UnitHistory = {
  __typename?: 'UnitHistory';
  history?: Maybe<Array<Maybe<YearVector>>>;
  unit?: Maybe<Scalars['String']>;
};

export type UnitVectorFloat = {
  __typename?: 'UnitVectorFloat';
  unit?: Maybe<Scalars['String']>;
  vector?: Maybe<Scalars['Float']>;
};

export type UnitVectorInt = {
  __typename?: 'UnitVectorInt';
  unit?: Maybe<Scalars['String']>;
  vector?: Maybe<Scalars['Int']>;
};

export type UpdateUserInputs = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  _id?: Maybe<Scalars['ID']>;
  activationCode?: Maybe<Scalars['String']>;
  confirmedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['EmailAddress']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  permissions?: Maybe<Array<Maybe<UserPermission>>>;
  profile?: Maybe<UserProfile>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserPermission = {
  __typename?: 'UserPermission';
  platform?: Maybe<AppType>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type YearVector = {
  __typename?: 'YearVector';
  vector?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['String']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type AuthorizeMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type AuthorizeMutation = {
  __typename?: 'Mutation';
  authorize?: {
    __typename?: 'AuthUserPayload';
    token: string;
    user: {
      __typename: 'User';
      _id?: string | null;
      confirmedAt?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      email?: any | null;
      role?: Role | null;
      profile?: {
        __typename: 'UserProfile';
        firstName?: string | null;
        lastName?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        companyName?: string | null;
        country?: string | null;
        phone?: string | null;
        state?: string | null;
        zipcode?: string | null;
      } | null;
    };
  } | null;
};

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  changePassword?: {
    __typename?: 'AuthUserPayload';
    token: string;
    user: {
      __typename: 'User';
      _id?: string | null;
      confirmedAt?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      email?: any | null;
      role?: Role | null;
      profile?: {
        __typename: 'UserProfile';
        firstName?: string | null;
        lastName?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        companyName?: string | null;
        country?: string | null;
        phone?: string | null;
        state?: string | null;
        zipcode?: string | null;
      } | null;
    };
  } | null;
};

export type ConfirmUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type ConfirmUserMutation = {
  __typename?: 'Mutation';
  confirmUser?: {
    __typename?: 'AuthUserPayload';
    token: string;
    user: {
      __typename: 'User';
      _id?: string | null;
      confirmedAt?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      email?: any | null;
      role?: Role | null;
      profile?: {
        __typename: 'UserProfile';
        firstName?: string | null;
        lastName?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        companyName?: string | null;
        country?: string | null;
        phone?: string | null;
        state?: string | null;
        zipcode?: string | null;
      } | null;
    };
  } | null;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename: 'User';
    _id?: string | null;
    confirmedAt?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    email?: any | null;
    role?: Role | null;
    profile?: {
      __typename: 'UserProfile';
      firstName?: string | null;
      lastName?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      companyName?: string | null;
      country?: string | null;
      phone?: string | null;
      state?: string | null;
      zipcode?: string | null;
    } | null;
  } | null;
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['EmailAddress'];
}>;

export type ForgotPasswordMutation = {
  __typename?: 'Mutation';
  forgotPassword?: boolean | null;
};

export type GetGrpDatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetGrpDatesQuery = {
  __typename?: 'Query';
  getGRPDates: Array<{
    __typename?: 'RiskPeriodDateBlock';
    startDate: any;
    endDate: any;
    deadlineDate: any;
  }>;
};

export type GetQuotesQueryVariables = Exact<{
  input?: InputMaybe<PaginationInputs>;
}>;

export type GetQuotesQuery = {
  __typename?: 'Query';
  getQuotes?: {
    __typename?: 'PaginatedQuotePayload';
    count?: number | null;
    limit?: number | null;
    offset?: number | null;
    items?: Array<{
      __typename?: 'Quote';
      _id?: string | null;
      createdAt?: any | null;
      coverageStructure?: {
        __typename?: 'CoverageStructure';
        programName?: string | null;
        startDate?: any | null;
        endDate?: any | null;
        limit?: {
          __typename?: 'UnitVectorInt';
          unit?: string | null;
          vector?: number | null;
        } | null;
        location?: { __typename?: 'Location'; name?: string | null } | null;
        strike?: {
          __typename?: 'UnitVectorFloat';
          unit?: string | null;
          vector?: number | null;
        } | null;
      } | null;
      salesAgent?: { __typename?: 'User'; _id?: string | null } | null;
      customerCounterparty?: {
        __typename?: 'User';
        profile?: {
          __typename?: 'UserProfile';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
      prices?: {
        __typename?: 'Prices';
        expirationDate?: any | null;
        agentCommission?: {
          __typename?: 'UnitVectorFloat';
          unit?: string | null;
          vector?: number | null;
        } | null;
        arbolCommission?: {
          __typename?: 'UnitVectorFloat';
          unit?: string | null;
          vector?: number | null;
        } | null;
        customerPremium?: {
          __typename?: 'UnitVectorFloat';
          unit?: string | null;
          vector?: number | null;
        } | null;
        riskCapitalPremium?: {
          __typename?: 'UnitVectorFloat';
          unit?: string | null;
          vector?: number | null;
        } | null;
      } | null;
      salesPlatform?: {
        __typename?: 'SalesPlatform';
        relativeStrike?: number | null;
      } | null;
    }> | null;
  } | null;
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = {
  __typename?: 'Query';
  getUsers?: Array<{
    __typename: 'User';
    _id?: string | null;
    confirmedAt?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    email?: any | null;
    role?: Role | null;
    profile?: {
      __typename: 'UserProfile';
      firstName?: string | null;
      lastName?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      companyName?: string | null;
      country?: string | null;
      phone?: string | null;
      state?: string | null;
      zipcode?: string | null;
    } | null;
  } | null> | null;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login?: {
    __typename?: 'AuthUserPayload';
    token: string;
    user: {
      __typename: 'User';
      _id?: string | null;
      confirmedAt?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      email?: any | null;
      role?: Role | null;
      profile?: {
        __typename: 'UserProfile';
        firstName?: string | null;
        lastName?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        companyName?: string | null;
        country?: string | null;
        phone?: string | null;
        state?: string | null;
        zipcode?: string | null;
      } | null;
    };
  } | null;
};

export type RegisterUserMutationVariables = Exact<{
  input: RegisterUserInputs;
}>;

export type RegisterUserMutation = {
  __typename?: 'Mutation';
  registerUser?: boolean | null;
};

export type ResendConfirmMutationVariables = Exact<{ [key: string]: never }>;

export type ResendConfirmMutation = {
  __typename?: 'Mutation';
  resendConfirm?: boolean | null;
};

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword?: {
    __typename?: 'AuthUserPayload';
    token: string;
    user: {
      __typename: 'User';
      _id?: string | null;
      confirmedAt?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      email?: any | null;
      role?: Role | null;
      profile?: {
        __typename: 'UserProfile';
        firstName?: string | null;
        lastName?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        companyName?: string | null;
        country?: string | null;
        phone?: string | null;
        state?: string | null;
        zipcode?: string | null;
      } | null;
    };
  } | null;
};

export type UserFragmentFragment = {
  __typename: 'User';
  _id?: string | null;
  confirmedAt?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email?: any | null;
  role?: Role | null;
  profile?: {
    __typename: 'UserProfile';
    firstName?: string | null;
    lastName?: string | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    companyName?: string | null;
    country?: string | null;
    phone?: string | null;
    state?: string | null;
    zipcode?: string | null;
  } | null;
};

export const UserFragment = gql`
  fragment userFragment on User {
    __typename
    _id
    confirmedAt
    createdAt
    updatedAt
    email
    role
    profile {
      __typename
      firstName
      lastName
      address1
      address2
      city
      companyName
      country
      firstName
      lastName
      phone
      state
      zipcode
    }
  }
`;
export const AuthorizeDocument = gql`
  mutation authorize($token: String!) {
    authorize(token: $token) {
      token
      user {
        ...userFragment
      }
    }
  }
  ${UserFragment}
`;
export const ChangePasswordDocument = gql`
  mutation changePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      token
      user {
        ...userFragment
      }
    }
  }
  ${UserFragment}
`;
export const ConfirmUserDocument = gql`
  mutation confirmUser($token: String!) {
    confirmUser(token: $token) {
      token
      user {
        ...userFragment
      }
    }
  }
  ${UserFragment}
`;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...userFragment
    }
  }
  ${UserFragment}
`;
export const ForgotPasswordDocument = gql`
  mutation forgotPassword($email: EmailAddress!) {
    forgotPassword(email: $email)
  }
`;
export const GetGrpDatesDocument = gql`
  query getGRPDates {
    getGRPDates {
      startDate
      endDate
      deadlineDate
    }
  }
`;
export const GetQuotesDocument = gql`
  query getQuotes($input: PaginationInputs) {
    getQuotes(input: $input) {
      items {
        _id
        createdAt
        coverageStructure {
          programName
          limit {
            unit
            vector
          }
          startDate
          endDate
          location {
            name
          }
          strike {
            unit
            vector
          }
        }
        salesAgent {
          _id
        }
        customerCounterparty {
          profile {
            firstName
            lastName
          }
        }
        prices {
          agentCommission {
            unit
            vector
          }
          arbolCommission {
            unit
            vector
          }
          customerPremium {
            unit
            vector
          }
          expirationDate
          riskCapitalPremium {
            unit
            vector
          }
        }
        salesPlatform {
          relativeStrike
        }
      }
      count
      limit
      offset
    }
  }
`;
export const GetUsersDocument = gql`
  query getUsers {
    getUsers {
      ...userFragment
    }
  }
  ${UserFragment}
`;
export const LoginDocument = gql`
  mutation login($email: EmailAddress!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        ...userFragment
      }
    }
  }
  ${UserFragment}
`;
export const RegisterUserDocument = gql`
  mutation registerUser($input: RegisterUserInputs!) {
    registerUser(input: $input)
  }
`;
export const ResendConfirmDocument = gql`
  mutation resendConfirm {
    resendConfirm
  }
`;
export const ResetPasswordDocument = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      token
      user {
        ...userFragment
      }
    }
  }
  ${UserFragment}
`;
