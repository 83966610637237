import { useNavigate } from 'react-router-dom';

import { useRegistration } from '../../hooks';

import { SignupPage } from './SignupPage';
import { ISignupPageSchema } from './SignupPage.schema';

export function SignupPageContainer() {
  const navigate = useNavigate();

  const { data: initialValues, setFields } = useRegistration();

  const onSubmit = async (data: ISignupPageSchema) => {
    setFields(data);

    navigate('/register');
  };

  return <SignupPage onSubmit={onSubmit} initialValues={initialValues} />;
}
