import { ITileMappings } from '../../components/RadioButtons';
import { AgricultureIcon, EnergyIcon } from '../../components/SVGs';
import i18n from '../../config/i18n';

export enum Industry {
  Agriculture = 'agriculture',
  Energy = 'energy',
}

type IndustryMappings = ITileMappings<Industry>;

// need to use i18n keys for labels
export function getIndustryMappings(): IndustryMappings[] {
  return [
    {
      icon: <AgricultureIcon />,
      disabled: false,
      label: i18n.t('labels.industry.agriculture', 'Agriculture'),
      value: Industry.Agriculture,
    },
    {
      icon: <EnergyIcon />,
      disabled: true,
      label: i18n.t('labels.industry.energy', 'Energy'),
      value: Industry.Energy,
    },
  ];
}
