import { LatLngTuple } from 'leaflet';
import { TileLayerProps } from 'react-leaflet';

import {
  DATA_COVERAGE_AREA,
  MapGridDatasetKeys,
  MapGridDatasetOption,
} from '../../@types/mapping';
import { config } from '../../config/config';
import { Program } from '../../helpers';

import { getPolygonFromLeafletRectBounds } from './helpers';

// Classes used by Leaflet to position controls
export const LEAFLET_POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
};

/**
 * MAP SETTINGS, STYLES and DEFAULTS
 * ========================
 *
 */

export const TileMapDefaultProps: TileLayerProps = {
  accessToken: config.map.mapboxAccessToken,
  attribution: config.map.attribution,
  id: config.map.streetViewId,
  url: config.map.mapboxUrl,
};

export const MapStyleId = {
  street: config.map.streetViewId,
  satellite: config.map.satelliteViewId,
};

export const MapStyleIdLabels = {
  [MapStyleId.street]: 'Street',
  [MapStyleId.satellite]: 'Satellite',
};

export const MapBoundaries: { [key: string]: LatLngTuple[] } = {
  NORTH: [
    [50, -360],
    [90, 360],
  ],
  SOUTH: [
    [-50, -360],
    [-90, 360],
  ],
};

// Pre-create boundary polygon for point testing
export const NorthBoundaryTurfPolygon = getPolygonFromLeafletRectBounds(
  MapBoundaries.NORTH,
);
export const SouthBoundaryTurfPolygon = getPolygonFromLeafletRectBounds(
  MapBoundaries.SOUTH,
);

type MapSettings = {
  name: string;
  center: LatLngTuple;
  zoom: number;
};

const DEFAULT_MAP_SETTINGS: {
  [key in 'US' | 'GLOBAL']: MapSettings;
} = {
  GLOBAL: {
    name: 'global',
    center: [2.65, 15],
    zoom: 2,
  },
  US: {
    name: 'us',
    center: [41.850033, -87.6500523],
    zoom: 4,
  },
};

/**
 * GRID DATASET CONSTANTS & CONFIGURATION
 * ========================
 * Program based datasets to mapping constraints (e.g. countries, states)
 */

export const MAP_GRID_DATASET_OPTIONS: {
  [key in MapGridDatasetKeys]: MapGridDatasetOption;
} = {
  CHIRPS_25: {
    dataCoverage: DATA_COVERAGE_AREA.OUTSIDE_US,
    label: 'CHIRPS .25',
    name: 'chirpsc_final_25-daily',
    offset: 0.125,
    step: 0.25,
  },
  CHIRPS_5: {
    dataCoverage: DATA_COVERAGE_AREA.OUTSIDE_US,
    label: 'CHIRPS .05',
    name: 'chirpsc_final_05-daily',
    offset: 0.125,
    step: 0.05,
  },
  PRISM: {
    dataCoverage: DATA_COVERAGE_AREA.INSIDE_US,
    label: 'PRISM',
    name: 'prism',
    offset: 0,
    step: 0.04166667,
  },
  PRISM_PRECIP_30: {
    dataCoverage: DATA_COVERAGE_AREA.INSIDE_US,
    label: 'PRISM',
    name: 'prismc-precip-daily',
    offset: 0,
    step: 0.04166667,
  },
  PRISM_PRECIP_DAILY: {
    dataCoverage: DATA_COVERAGE_AREA.INSIDE_US,
    label: 'PRISM',
    name: 'prismc-precip-daily',
    offset: 0,
    step: 0.04166667,
  },
  CPC_US: {
    dataCoverage: DATA_COVERAGE_AREA.INSIDE_US,
    label: 'CPC',
    name: 'cpcc_precip_us-daily',
    offset: 0.125,
    step: 0.25,
  },
  CPC_TEMP: {
    dataCoverage: DATA_COVERAGE_AREA.OUTSIDE_US,
    label: 'CPC',
    name: 'cpc_temp-daily',
    offset: 0.125,
    step: 0.05,
  },
};

/**
 * MAP OPTIONS
 * ========================
 * Map views and datasets configurations
 */

export type MapViewOption = {
  defaultDataSet: MapGridDatasetOption;
  dataSets: MapGridDatasetOption[];
  defaultZoom: number;
  defaultCenter: LatLngTuple;
  name: string;
};

export const MAP_VIEW_TYPES: {
  [key: string]: MapViewOption;
} = {
  RAIN: {
    dataSets: [
      MAP_GRID_DATASET_OPTIONS.CHIRPS_25,
      MAP_GRID_DATASET_OPTIONS.PRISM_PRECIP_30,
      MAP_GRID_DATASET_OPTIONS.CPC_US,
    ],
    defaultCenter: DEFAULT_MAP_SETTINGS.GLOBAL.center,
    defaultDataSet: MAP_GRID_DATASET_OPTIONS.CHIRPS_25,
    defaultZoom: DEFAULT_MAP_SETTINGS.GLOBAL.zoom,
    name: DEFAULT_MAP_SETTINGS.GLOBAL.name,
  },
};

/**
 * PROGRAM BASE MAP OPTIONS
 * ========================
 * Combining map options by program types
 */

type ProgramKeys = keyof typeof Program;

const PROGRAM_TO_MAP_VIEW_TYPE: {
  [key in ProgramKeys]: MapViewOption;
} = {
  [Program.GRP]: MAP_VIEW_TYPES.RAIN,
  [Program.XSR]: MAP_VIEW_TYPES.RAIN,
};

export const getMapViewTypeFromProgram = (program: ProgramKeys) => {
  return PROGRAM_TO_MAP_VIEW_TYPE[program];
};
