import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Link, Stack, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Button } from '../../components/Button';
import {
  ControllerPasswordField,
  ControllerTextField,
} from '../../components/Inputs';
import { UnprotectedLayout } from '../../components/UnprotectedLayout';

import { ILoginPageSchema, getLoginPageSchema } from './LoginPage.schema';

interface IProps {
  errorMessage?: string;
  onSubmit: SubmitHandler<ILoginPageSchema>;
}

export function LoginPage({ errorMessage, onSubmit }: IProps) {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(getLoginPageSchema()),
  });

  return (
    <UnprotectedLayout title={t('pages.login.title', 'Login')}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        marginX="auto"
        marginTop="20px"
        maxWidth={360}
        width="100%"
      >
        <Stack spacing={2} marginBottom={4}>
          <ControllerTextField
            name="email"
            control={control}
            label={t('common.labels.email')}
            fullWidth
          />
          <ControllerPasswordField
            name="password"
            control={control}
            label={t('common.labels.password')}
            fullWidth
          />
          {errorMessage && (
            <Typography marginBottom={1} color="error">
              {errorMessage}
            </Typography>
          )}
          <Link
            to="/forgot"
            color="primary"
            component={RouterLink}
            sx={{ alignSelf: 'flex-end', textDecoration: 'none' }}
          >
            {t('pages.login.forgotPassword', 'Forgot password')}
          </Link>
        </Stack>
        <Button size="large" type="submit" fullWidth>
          {t('common.actions.signin', 'Sign In')}
        </Button>
        <Box mt={4} textAlign="center">
          <Link to="/" color="primary" component={RouterLink}>
            {t('pages.login.noAccountCTA', "Don't have an account?")}
          </Link>
        </Box>
      </Box>
    </UnprotectedLayout>
  );
}
