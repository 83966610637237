import { Box, RadioGroup } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Industry } from '../../helpers';
import { programMappingGetters } from '../../helpers/mappings';
import { useQuoteCalculator } from '../../hooks';
import { QuoteCalculatorLayout } from '../QuoteCalculator';
import { RadioTile } from '../RadioButtons';
import { ProgramTileInfo } from '../RadioButtons/ProgramInfoTileExtra';

export function SelectProgram() {
  const { t } = useTranslation();
  const { input, setStep, activeStepIndex } = useQuoteCalculator();

  // Screen guard to prevent user from
  // skipping select an industry input
  useEffect(() => {
    if (!input.industry) {
      setStep(activeStepIndex - 1);
    }
  }, [activeStepIndex, input.industry, setStep]);

  const selectedIndustry = input.industry || Industry.Agriculture;
  const getProgramMapping = programMappingGetters[selectedIndustry];

  const { control, getValues, watch } = useForm({
    defaultValues: {
      program: input?.program,
    },
  });

  const onNextClick = () => {
    return getValues();
  };

  const programSelected = watch('program');

  return (
    <QuoteCalculatorLayout
      nextDisabled={!programSelected}
      onNext={onNextClick}
      title={t('calculator.selectProgram.title', 'Select a Program')}
    >
      <Box>
        <Box component="form">
          <Controller
            control={control}
            name="program"
            render={({ field }) => (
              <RadioGroup
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                  gridAutoRows: '1fr',
                  gap: 2,
                }}
                {...field}
                // Keep typing happy and prevent undefined from
                // creating uncontrolled inputs error by
                // defaulting to an empty string
                value={field.value || ''}
              >
                {getProgramMapping().map((program) => (
                  <RadioTile
                    key={program.value}
                    disabled={program.disabled}
                    id={`${program.value}-input-radio`}
                    label={program.label}
                    svg={program.icon}
                    value={program.value}
                  >
                    <ProgramTileInfo
                      programDescription={program?.extra?.description || ''}
                      deadlineInfo={program?.extra?.extraInfo || ''}
                    />
                  </RadioTile>
                ))}
              </RadioGroup>
            )}
          />
        </Box>
      </Box>
    </QuoteCalculatorLayout>
  );
}
