import { Box } from '@mui/material';
import { useCallback } from 'react';

import { useQuoteCalculator } from '../../hooks';
import { ICalculatorInput } from '../../store/calculator';
import { PageTitle } from '../PageTitle';

import { QuoteCalculatorFooter } from './QuoteCalculatorFooter';

interface IProps {
  children: React.ReactNode;
  input?: ICalculatorInput;
  nextDisabled?: boolean;
  onNext?: () => Partial<ICalculatorInput> | undefined;
  title: string;
}

export function QuoteCalculatorLayout({
  children,
  input,
  nextDisabled,
  onNext,
  title,
}: IProps) {
  const { activeStepIndex, completeStep, previousStep, steps, reset } =
    useQuoteCalculator();

  const handleNextClick = useCallback(() => {
    const newInputs = onNext ? onNext() : input;

    if (newInputs) {
      completeStep(newInputs);
    }
  }, [completeStep, input, onNext]);

  const handlePreviousClick = useCallback(() => {
    previousStep();
  }, [previousStep]);

  const handleCancelClick = useCallback(() => {
    reset();
  }, [reset]);

  const numberOfSteps = steps.length;
  const lastStepIndex = numberOfSteps - 1;
  const isLastStep = activeStepIndex === lastStepIndex;

  const canGoBack = activeStepIndex > 0;

  return (
    <Box data-testid="quote-calculator-layout">
      <PageTitle textAlign="center">{title}</PageTitle>
      <Box>{children}</Box>
      <QuoteCalculatorFooter
        canCancel={canGoBack}
        canBack={canGoBack}
        nextDisabled={nextDisabled || isLastStep}
        handleCancelClick={handleCancelClick}
        handleNextClick={handleNextClick}
        handlePreviousClick={handlePreviousClick}
      />
    </Box>
  );
}
