import { useLocation } from 'react-router-dom';

import { SignupAccountCreatedPage } from './SignupAccountCreatedPage';

export function SignupAccountCreatedPageContainer() {
  const location = useLocation();

  const state = location.state as { email: string };
  const email = state?.email;

  return <SignupAccountCreatedPage email={email} />;
}
