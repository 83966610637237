import { Components, CSSInterpolation } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';

import { baseTheme } from './base';

// Exported for unit testing
export const buttonVarientGradientInterpolation: CSSInterpolation = {
  color: baseTheme.palette.common.white,
  background: baseTheme.palette.gradient.main,
  ':disabled': {
    color: baseTheme.palette.common.white,
    background: baseTheme.palette.action.disabledBackground,
  },
  ':hover': {
    background: baseTheme.palette.gradient.light,
  },
};

export const components: Components = {
  MuiAutocomplete: {
    styleOverrides: {
      inputRoot: {
        backgroundColor: 'white',
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      input: {
        'label[data-shrink=false] + .MuiInputBase-formControl &::placeholder': {
          // mui base has 'opacity: 0 !important by default
          // need !important to override
          opacity: '1 !important',
        },
        '::placeholder': {
          color: baseTheme.palette.common.grey,
          opacity: 1,
        },
      },
      root: {
        'label + &': {
          marginTop: 0,
        },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      input: {
        'label[data-shrink=false] + .MuiInputBase-formControl &::placeholder': {
          color: baseTheme.palette.common.grey,
          // mui base has 'opacity: 0 !important by default
          // need !important to override
          opacity: '1 !important',
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      sizeSmall: {
        input: {
          padding: '8.5px 14px',
        },
      },
      root: {
        boxShadow: baseTheme.shadows[2],
      },
      multiline: {
        padding: '0',
      },
      inputAdornedStart: {
        paddingLeft: '0',
      },
      inputAdornedEnd: {
        paddingRight: '0',
      },
      input: {
        'label[data-shrink=false] + .MuiInputBase-formControl &::placeholder': {
          // mui base has 'opacity: 0 !important by default
          // need !important to override
          opacity: '1 !important',
        },
        '::placeholder': {
          color: baseTheme.palette.common.grey,
          opacity: 1,
        },
        padding: '12.5px 14px',
      },
      notchedOutline: {
        legend: {
          display: 'none',
        },
        top: 0,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      filled: {
        color: baseTheme.palette.common.black,
        left: '12px',
      },
      outlined: {
        marginBottom: '8px',
        position: 'static',
      },
      root: {
        transform: 'none',
      },
      standard: {
        position: 'static',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: 'gradient',
    },
    variants: [
      {
        props: { variant: 'gradient', size: 'small' },
        style: {
          ...buttonVarientGradientInterpolation,
          fontSize: baseTheme.typography.pxToRem(13),
          padding: '4px 10px',
        },
      },
      {
        props: { variant: 'gradient', size: 'medium' },
        style: {
          ...buttonVarientGradientInterpolation,
          fontSize: baseTheme.typography.pxToRem(14),
          padding: '6px 16px',
        },
      },
      {
        props: { variant: 'gradient', size: 'large' },
        style: {
          ...buttonVarientGradientInterpolation,
          fontSize: baseTheme.typography.pxToRem(15),
          padding: '8px 22px',
        },
      },
    ],
    styleOverrides: {
      outlined: {
        borderWidth: '2px',
        ':hover': {
          borderWidth: '2px',
        },
      },
      contained: {
        color: baseTheme.palette.common.white,
        ':disabled': {
          color: baseTheme.palette.common.white,
        },
      },
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        color: baseTheme.palette.common.lightGrey,
        '&.Mui-active text': {
          fill: baseTheme.palette.common.white,
          fontWeight: '700',
        },
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        backgroundColor: baseTheme.palette.common.white,
      },
    },
  },
};
