import { Link, TableCell, TableRow } from '@mui/material';

interface IProps {
  name: string;
  href: string;
  status: string;
}

export function DocumentRow({ name, href, status }: IProps) {
  return (
    <TableRow>
      <TableCell>
        <Link rel="noopener noreferrer" target="_blank" href={href}>
          {name}
        </Link>
      </TableCell>
      <TableCell>{status}</TableCell>
    </TableRow>
  );
}
