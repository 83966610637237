import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../Button';
import { ModalBase } from '../ModalBase';

interface IProps {
  email?: string;
}

export function EmailVerifiedSuccessModal({ email }: IProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <ModalBase
      open={open}
      title={t('modals.emailVerified.title', 'Email Verified')}
    >
      <Box>
        <Typography>
          {t(
            'modals.emailVerified.message',
            'You are now logged in as {{email}}',
            {
              email,
            },
          )}
        </Typography>
        <Box sx={{ mt: 2, mx: 'auto', maxWidth: 150 }}>
          <Button fullWidth size="small" onClick={closeModal}>
            {t('common.actions.dismiss', 'Dismiss')}
          </Button>
        </Box>
      </Box>
    </ModalBase>
  );
}
