import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  completeStep,
  ICalculatorInput,
  nextStep,
  previousStep,
  reset,
  setStep,
} from '../store/calculator';
import { RootState } from '../store/configureStore';

export function useQuoteCalculator() {
  const dispatch = useDispatch();

  const completeStepHandler = useCallback(
    (input: ICalculatorInput) => {
      dispatch(completeStep(input));
    },
    [dispatch],
  );

  const nextStepHandler = useCallback(() => {
    dispatch(nextStep());
  }, [dispatch]);

  const previousStepHandler = useCallback(() => {
    dispatch(previousStep());
  }, [dispatch]);

  const resetHandler = useCallback(() => {
    dispatch(reset());
  }, [dispatch]);

  const setStepHandler = useCallback(
    (step: number) => {
      dispatch(setStep(step));
    },
    [dispatch],
  );

  return {
    activeStepIndex: useSelector(
      (state: RootState) => state.calculator.activeStepIndex,
    ),
    activeStep: useSelector(
      (state: RootState) =>
        state.calculator.steps[state.calculator.activeStepIndex],
    ),
    completedSteps: useSelector(
      (state: RootState) => state.calculator.completedSteps,
    ),
    completeStep: completeStepHandler,
    input: useSelector((state: RootState) => state.calculator.input),
    nextStep: nextStepHandler,
    previousStep: previousStepHandler,
    reset: resetHandler,
    steps: useSelector((state: RootState) => state.calculator.steps),
    setStep: setStepHandler,
  };
}
