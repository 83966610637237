import { createTheme } from '@mui/material';

const gradients = {
  main: 'linear-gradient(#4bae4c,#2cb16e)',
  light: 'linear-gradient(#60b760,#43ba7f)',
};

export const baseTheme = createTheme({
  palette: {
    action: {
      disabledBackground: '#afafaf',
      disabled: '#afafaf',
    },
    common: {
      darkGrey: '#949ba2',
      lightGrey: '#eee',
      grey: '#afafaf',
    },
    gradient: {
      main: gradients.main,
      light: gradients.light,
    },
    primary: {
      main: '#4cae4d',
    },
    secondary: {
      main: '#18253c',
    },
  },
});
