export function AgricultureIcon() {
  return (
    <svg
      height="100%"
      width="100%"
      viewBox="0 0 124 102"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M103.87 0c10.518 0 19.065 8.434 19.248 18.908l.003.342v79.949a2.5 2.5 0 0 1-2.336 2.494l-.164.006-23.5.003v-.003H9.62a2.5 2.5 0 0 1-2.495-2.336l-.005-.164V55.047a6.597 6.597 0 0 1-2.87-.786l-.217-.123-.236-.147C.906 52.09.017 48.257 1.793 45.224l.147-.235L16.54 22.797a6.574 6.574 0 0 1 2.017-1.959l.275-.161L50.233 3.368a6.5 6.5 0 0 1 5.927-.178l.067.033.281.145 28.117 15.498C84.829 8.412 93.368 0 103.87 0Zm14.25 87.82h-18.5v8.881l18.5-.003V87.82ZM53.37 16.481 26.517 31.283l-13.716 20.85a6.527 6.527 0 0 1-.68.866v43.699h82.5V52.989a6.76 6.76 0 0 1-.523-.628l-.158-.228-13.718-20.85L53.37 16.481Zm64.75 57.462h-18.5v8.877h18.5v-8.877ZM57.01 34.602a1 1 0 0 1 .911.588l20.586 45.6a1 1 0 0 1-.912 1.412h-7.042a1 1 0 0 1-.918-.604l-4.5-10.412h-23.8l-4.432 10.408a1 1 0 0 1-.92.608h-6.905a1 1 0 0 1-.912-1.41l20.52-45.6a1 1 0 0 1 .912-.59Zm61.111 25.463h-18.5v8.878h18.5v-8.878ZM53.235 43.442 44.327 64.25H62.21l-8.976-20.808Zm64.885 2.746h-12.696a6.502 6.502 0 0 1-5.804 8.866v.011h18.5v-8.877ZM53.867 7.645a1.5 1.5 0 0 0-1.22.102L21.31 25.017l-.145.085a1.5 1.5 0 0 0-.45.443L6.153 47.68l-.079.125c-.4.684-.195 1.57.417 1.973l.125.078a1.5 1.5 0 0 0 2.008-.472l14.395-21.883 30.352-16.73 30.35 16.73 14.36 21.83.083.12a1.5 1.5 0 0 0 2.46-1.716L86.068 25.608l-.095-.138a1.5 1.5 0 0 0-.477-.415L54.161 7.782l-.163-.083ZM118.12 32.31H96.46l5.91 8.984v-.106h15.75V32.31ZM103.87 5c-7.764 0-14.079 6.21-14.246 13.936l-.003.314v2.796c.138.154.27.317.4.489l.182.262 2.968 4.513h24.95v-8.06c0-7.765-6.211-14.08-13.936-14.247L103.87 5Z"
        fill="currentColor"
      />
    </svg>
  );
}
