import { Box } from '@mui/material';
import { booleanPointInPolygon } from '@turf/turf';
import { GeoJSON as GeoJSONType } from 'geojson';
import { LatLng, Map, TileLayer as LeafletTileLayer } from 'leaflet';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  MapContainer,
  Rectangle,
  TileLayer,
  ZoomControl,
  GeoJSON,
  LayerGroup,
} from 'react-leaflet';

import { MapGridDatasetOption } from '../../@types/mapping';

import { LocationMapSearch } from './LocationMapSearch';
import { MapDatasetSelector } from './MapDatasetSelector';
import { MapDebugger } from './MapDebugger';
import { MapGridSelector } from './MapGridSelector';
import { MapOnChangesTracking } from './MapOnChangesTracking';
import { MinimapControl } from './MinimapToggle';
import {
  MapBoundaries,
  MapStyleId,
  MapViewOption,
  NorthBoundaryTurfPolygon,
  SouthBoundaryTurfPolygon,
  TileMapDefaultProps,
} from './constants';
import { debugPathColorOptions, isPointInGeoJSON } from './helpers';

const defaultOptions = {
  ...TileMapDefaultProps,
  id: MapStyleId.street,
};

const defaultGeoJsonPathOptions = {
  opacity: 0,
  fillColor: 'transparent',
  fillOpacity: 0,
};

interface IProps {
  countriesGeoJSON: GeoJSONType;
  debug?: boolean;
  initialDataset?: MapGridDatasetOption;
  initialPosition?: LatLng;
  mapViewOption: MapViewOption;
  onSelected?: (coordinates: LatLng, dataset: MapGridDatasetOption) => void;
  usaGeoJSON: GeoJSONType;
}

export function MapLocationSelector({
  countriesGeoJSON,
  debug = true,
  initialDataset,
  initialPosition,
  mapViewOption,
  onSelected,
  usaGeoJSON,
}: IProps) {
  // Refs
  const mapRef = useRef<Map | null>(null);
  const tileLayerRef = useRef<LeafletTileLayer>(null);

  // State
  const [position, setPosition] = useState<LatLng>(
    initialPosition || new LatLng(0, 0),
  );
  const [selectedPosition, setSelectedPosition] = useState<LatLng | undefined>(
    initialPosition,
  );
  const [selectedDataset, setSelectedDataset] = useState<MapGridDatasetOption>(
    initialDataset || mapViewOption.defaultDataSet,
  );

  const [zoomLevel, setZoom] = useState(mapViewOption.defaultZoom);
  const [mapStyleId, setMapStyleId] = useState(MapStyleId.street);

  // Boundary checking
  // Uses turf.js to check if the current position is within the boundaries
  // NOTE: Turf uses [lng, lat] while leaflet uses [lat, lng]
  const IS_INISDE_USA = useMemo(
    () => isPointInGeoJSON([position.lng, position.lat], usaGeoJSON),
    [position.lat, position.lng, usaGeoJSON],
  );

  const IS_INSIDE_COUNTRIES = useMemo(
    () => isPointInGeoJSON([position.lng, position.lat], countriesGeoJSON),
    [position.lat, position.lng, countriesGeoJSON],
  );

  const IS_INSIDE_NORTH_BOUNDARY = useMemo(() => {
    return booleanPointInPolygon(
      [position.lng, position.lat],
      NorthBoundaryTurfPolygon,
    );
  }, [position.lat, position.lng]);

  const IS_INSIDE_SOUTH_BOUNDARY = useMemo(() => {
    return booleanPointInPolygon(
      [position.lng, position.lat],
      SouthBoundaryTurfPolygon,
    );
  }, [position.lat, position.lng]);

  // Sets new tile layer when map style id chances
  useEffect(() => {
    if (tileLayerRef.current) {
      tileLayerRef.current.setUrl(
        `https://api.mapbox.com/styles/v1/${mapStyleId}/tiles/{z}/{x}/{y}?access_token={accessToken}`,
      );
    }
  }, [mapStyleId]);

  // Event handlers
  const onCreated = useCallback(
    (map: Map) => {
      if (initialPosition) {
        map.setView([initialPosition.lat, initialPosition.lng], 8);
      }
    },
    [initialPosition],
  );

  const toggleMaptypeHandler = useCallback(() => {
    if (mapStyleId === MapStyleId.street) {
      setMapStyleId(MapStyleId.satellite);
    } else if (mapStyleId === MapStyleId.satellite) {
      setMapStyleId(MapStyleId.street);
    }
  }, [mapStyleId]);

  const onLocationSelected = useCallback(
    (latLng: LatLng) => {
      setSelectedPosition(latLng);

      if (onSelected) {
        onSelected(latLng, selectedDataset);
      }
    },
    [onSelected, selectedDataset],
  );

  const minimapType =
    mapStyleId === MapStyleId.street ? MapStyleId.satellite : MapStyleId.street;

  const isValidLocation =
    IS_INSIDE_COUNTRIES &&
    !IS_INSIDE_NORTH_BOUNDARY &&
    !IS_INSIDE_SOUTH_BOUNDARY;

  return (
    <Box height="100%" width="100%">
      <MapContainer
        whenCreated={(map) => {
          onCreated(map);
          mapRef.current = map;
        }}
        preferCanvas
        center={mapViewOption.defaultCenter}
        doubleClickZoom={false}
        scrollWheelZoom
        style={{ height: '100%', width: '100%' }}
        worldCopyJump
        zoom={mapViewOption.defaultZoom}
        zoomControl={false}
      >
        <TileLayer ref={tileLayerRef} {...defaultOptions} />
        <MapOnChangesTracking
          onPositionChange={setPosition}
          onZoomChange={setZoom}
        />
        <MapGridSelector
          debug={debug}
          isValidLocation={isValidLocation}
          offset={selectedDataset.offset}
          onSelect={onLocationSelected}
          position={position}
          selectedPosition={selectedPosition}
          step={selectedDataset.step}
        />
        {/* GeoJSON Bounds */}
        {countriesGeoJSON && (
          <GeoJSON
            data={countriesGeoJSON}
            pathOptions={
              debug
                ? debugPathColorOptions(IS_INSIDE_COUNTRIES && !IS_INISDE_USA)
                : defaultGeoJsonPathOptions
            }
          />
        )}
        {usaGeoJSON && (
          <GeoJSON
            data={usaGeoJSON}
            pathOptions={
              debug
                ? debugPathColorOptions(IS_INISDE_USA)
                : defaultGeoJsonPathOptions
            }
          />
        )}
        <Rectangle
          bounds={MapBoundaries.NORTH}
          pathOptions={{ color: 'white', opacity: 0.5 }}
        />
        <Rectangle
          bounds={MapBoundaries.SOUTH}
          pathOptions={{ color: 'white', opacity: 0.5 }}
        />
        {/* Controls */}
        <LayerGroup>
          <LocationMapSearch />
          <MapDatasetSelector
            currentDataSet={selectedDataset}
            datasetOptions={mapViewOption.dataSets}
            isInsideUSA={IS_INISDE_USA}
            onDatasetChange={setSelectedDataset}
            position="topright"
          />
          {debug && (
            <MapDebugger
              currentPosition={position}
              isInsideUSA={IS_INISDE_USA}
              isValidLocation={isValidLocation}
              position="topleft"
              selectedPosition={selectedPosition}
              zoom={zoomLevel}
            />
          )}
          <ZoomControl position="bottomright" />
          <MinimapControl
            mapType={minimapType}
            onClick={toggleMaptypeHandler}
            position="bottomleft"
          />
        </LayerGroup>
      </MapContainer>
    </Box>
  );
}
