import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChangePasswordSuccessModal } from '../../components/Modals';
import { getTranslatedErrorMessage } from '../../helpers';
import { useAuth } from '../../hooks';
import { useChangePasswordMutation } from '../../store/api';

import { ChangePasswordPage } from './ChangePasswordPage';
import { IChangePasswordPageSchema } from './ChangePasswordPage.schema';

export function ChangePasswordPageContainer() {
  const [mutate, { error, data, isLoading, isSuccess }] =
    useChangePasswordMutation();
  const navigate = useNavigate();

  const { login } = useAuth();

  const onSubmit = useCallback(
    async (values: IChangePasswordPageSchema) => {
      const { currentPassword, newPassword } = values;

      await mutate({ currentPassword, newPassword });
    },
    [mutate],
  );

  const onClose = useCallback(() => {
    const payload = data?.changePassword;

    if (payload) {
      login(payload);
      navigate('/account');
    }
  }, [data, login, navigate]);

  const errorMessage = getTranslatedErrorMessage(error);

  return (
    <Fragment>
      <ChangePasswordPage
        onSubmit={onSubmit}
        isLoading={isLoading}
        errorMessage={errorMessage}
      />
      <ChangePasswordSuccessModal open={isSuccess} onClose={onClose} />
    </Fragment>
  );
}
