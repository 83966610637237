import { Box, BoxProps } from '@mui/material';
import { ReactNode } from 'react';

interface IProps extends BoxProps<'svg'> {
  children?: ReactNode;
}

export function CalendarTodayIconWithText({
  children = '',
  ...svgBoxProps
}: IProps) {
  return (
    <Box
      component="svg"
      height="100%"
      viewBox="0 0 24 24"
      width="100%"
      {...svgBoxProps}
    >
      <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"></path>
      <text
        fontFamily="'Space Mono', monospace"
        fontSize="0.3rem"
        textAnchor="middle"
        x="12"
        y="16"
      >
        {children}
      </text>
    </Box>
  );
}
