/* eslint-disable no-constant-condition */
import { useTranslation } from 'react-i18next';

import { useGetGRPDatesQuery } from '../../../store/api';
import { InnerContainerLoader } from '../../Loaders';
import { IDateSelectorContainerProps } from '../../QuoteCalculatorScreens/interfaces';

import { RiskPeriodBlockDates } from './RiskPeriodBlockDates';

export function GRPBlockDatesSelectorContainer({
  initialValues,
  updateInput,
}: IDateSelectorContainerProps) {
  const { t } = useTranslation();
  const { isLoading, data } = useGetGRPDatesQuery({});

  const initialValue = initialValues && JSON.stringify(initialValues);

  const dateBlocks = data?.getGRPDates;

  if (isLoading) {
    return (
      <InnerContainerLoader
        message={t(
          'calculator.selectRiskPeriod.grpDatesLoading',
          'Retrieving latest valid GRP dates ...',
        )}
      />
    );
  }

  return (
    <RiskPeriodBlockDates
      initialValue={initialValue}
      dateBlocks={dateBlocks}
      onSelect={updateInput}
    />
  );
}
