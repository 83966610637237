import { Box, Typography } from '@mui/material';

import { useQuoteCalculator } from '../../hooks';
import { QuoteCalculatorLayout } from '../QuoteCalculator';

export function SummaryReview() {
  const { input } = useQuoteCalculator();

  const [startDate, endDate, deadlineDate] = (input?.riskPeriod || []).map(
    (date) => new Date(date || ''),
  );

  return (
    <QuoteCalculatorLayout title="Select a Client">
      <Box sx={{ '& strong': { display: 'block' }, '& p': { mb: 1 } }}>
        <Typography>
          <strong>Industry</strong> {input?.industry}
        </Typography>
        <Typography>
          <strong>Program</strong> {input?.program}
        </Typography>
        <Typography>
          <strong>Risk Period</strong>
          {startDate?.toDateString()} - {endDate?.toDateString()}.
          <br /> deadline: {deadlineDate?.toDateString()} @{' '}
          {deadlineDate?.toLocaleTimeString()}
        </Typography>
        <Typography>
          <strong>Location</strong> {input?.location}
        </Typography>
        <Typography>
          <strong>Options Term</strong> {input?.optionsTerm}
        </Typography>
        <Typography>
          <strong>Client</strong> {input?.client}
        </Typography>
      </Box>
    </QuoteCalculatorLayout>
  );
}
