import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Link, MenuItem, Stack, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Button } from '../../components/Button';
import { ControllerTextField } from '../../components/Inputs';
import { UnprotectedLayout } from '../../components/UnprotectedLayout';
import { countriesCodeMappings, usaStateCodesMap } from '../../helpers';

import {
  ISignupAccountDetailsPageSchema,
  getSignupAccountDetailsPageSchema,
} from './SignupAccountDetailsPage.schema';

interface IProps {
  errorMessage?: string;
  isLoading: boolean;
  onSubmit: SubmitHandler<ISignupAccountDetailsPageSchema>;
}

export function SignupAccountDetailsPage({
  errorMessage,
  isLoading,
  onSubmit,
}: IProps) {
  const { t } = useTranslation();

  const { control, handleSubmit, watch } =
    useForm<ISignupAccountDetailsPageSchema>({
      defaultValues: {
        activationCode: '',
        address1: '',
        city: '',
        country: 'US',
        companyName: '',
        firstName: '',
        lastName: '',
        state: 'AL',
        zipcode: '',
      },
      resolver: zodResolver(getSignupAccountDetailsPageSchema()),
    });

  const watchCountry = watch('country');

  return (
    <UnprotectedLayout
      title={t('pages.accountdetails.title', 'Account Details')}
    >
      <Box
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        marginX="auto"
        marginTop="20px"
        maxWidth={360}
        width="100%"
      >
        <Stack spacing={1}>
          <Stack direction="row" spacing={2}>
            <ControllerTextField
              disabled={isLoading}
              control={control}
              name="firstName"
              label={t('common.labels.firstName', 'First Name')}
            />
            <ControllerTextField
              disabled={isLoading}
              control={control}
              name="lastName"
              label={t('common.labels.lastName', 'Last Name')}
            />
          </Stack>
          <ControllerTextField
            disabled={isLoading}
            control={control}
            name="companyName"
            label={t(
              'common.labels.companyNameField',
              'Company Name (Optional)',
            )}
          />
          <ControllerTextField
            disabled={isLoading}
            control={control}
            name="activationCode"
            label={t(
              'common.labels.activationField',
              'Activation Code (if none, leave blank)',
            )}
          />
          <ControllerTextField
            disabled={isLoading}
            control={control}
            name="country"
            label={t('common.labels.country', 'Country')}
            select
          >
            {countriesCodeMappings.map(({ name, code }) => (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            ))}
          </ControllerTextField>
          <ControllerTextField
            disabled={isLoading}
            control={control}
            name="address1"
            label={t('common.labels.address1', 'Address Line')}
          />
          <ControllerTextField
            disabled={isLoading}
            control={control}
            name="city"
            label={t('common.labels.city', 'City')}
          />
          {watchCountry === 'US' && (
            <Stack direction="row" spacing={2}>
              <ControllerTextField
                disabled={isLoading}
                control={control}
                name="state"
                label={t('common.labels.state', 'State')}
                select
                sx={{ flex: '1' }}
              >
                {usaStateCodesMap.map(({ name, code }) => (
                  <MenuItem key={code} value={code}>
                    {code}
                  </MenuItem>
                ))}
              </ControllerTextField>
              <ControllerTextField
                disabled={isLoading}
                control={control}
                name="zipcode"
                label={t('common.labels.zipcode', 'Zipcode')}
                sx={{ flex: '2' }}
              />
            </Stack>
          )}
        </Stack>
        {errorMessage && (
          <Box marginY={2}>
            <Typography color="error">{errorMessage}</Typography>
          </Box>
        )}
        <Box marginY={3}>
          <Button disabled={isLoading} fullWidth size="large" type="submit">
            {t('pages.signup.signupButton', 'Sign up')}
          </Button>
        </Box>
      </Box>
      <Link to="/login" color="primary" component={RouterLink}>
        {t('pages.signup.haveAccount', 'Have an account already?')}
      </Link>
    </UnprotectedLayout>
  );
}
