import { Box, Grid, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { InfoBox } from '../../components/AccountDetails/InfoBox';
import { Container } from '../../components/Container';
import { PageTitle } from '../../components/PageTitle';
import { config } from '../../config/config';

const emailAddressContact = config.contact.email;
const mailtoAddress = `mailto:${emailAddressContact}`;

export function HelpPage() {
  const { t } = useTranslation();

  return (
    <Container>
      <Box maxWidth={800}>
        <PageTitle>{t('pages.contact.title', 'Contact')}</PageTitle>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <InfoBox label={t('common.labels.email')}>
              <Link href={mailtoAddress}>{emailAddressContact}</Link>
            </InfoBox>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
