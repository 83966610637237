import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '../../config/config';

import { IBingLocationResponse } from './interfaces';

const BING_API_BASE_URL = config.map.bingMapBaseUrl;
const BING_API_KEY = config.map.bingMapAPIKey;

export const getGeocoderUrl = (query: string) =>
  `Locations?key=${BING_API_KEY}&maxResults=5&query=${query}`;
export const getReverseGeocoderUrl = (query: string) =>
  `Locations/${query}?key=${BING_API_KEY}`;

export const bingMapApi = createApi({
  reducerPath: 'bingMapApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BING_API_BASE_URL,
  }),
  endpoints: (builder) => ({
    queryBingGeocoder: builder.query<IBingLocationResponse, string>({
      query: getGeocoderUrl,
    }),
    reverseGeocoder: builder.query<IBingLocationResponse, string>({
      query: getReverseGeocoderUrl,
    }),
  }),
});

export const {
  useLazyQueryBingGeocoderQuery,
  useLazyReverseGeocoderQuery,
  useQueryBingGeocoderQuery,
  useReverseGeocoderQuery,
} = bingMapApi;
