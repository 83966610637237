import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IRegistrationState {
  email: string;
  password: string;
  phone: string;
}

export const initialRegistrationState: IRegistrationState = {
  email: '',
  password: '',
  phone: '',
};

export const registrationSlice = createSlice({
  name: 'registration',
  initialState: initialRegistrationState,
  reducers: {
    reset: () => ({ ...initialRegistrationState }),
    setFields: (state, action: PayloadAction<Partial<IRegistrationState>>) => {
      const { payload } = action;

      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { reset, setFields } = registrationSlice.actions;
