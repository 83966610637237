import { Box, RadioGroup } from '@mui/material';
import { addHours, format } from 'date-fns';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { RiskPeriodDateBlock } from '../../../graphql/generated';
import { convertDateRangeToJSON } from '../../../helpers';
import { ICalculatorInput } from '../../../store/calculator';
import { CalculatorStepValue } from '../../../store/calculator/constants';
import { RadioTile } from '../../RadioButtons';
import { ProgramTileInfo } from '../../RadioButtons/ProgramInfoTileExtra';
import { CalendarTodayIconWithText } from '../../SVGs';

// Component helper
const createLabel = (startDate: Date, endDate: Date) => {
  return `${format(startDate, 'MMMM d')} - ${format(
    endDate,
    'MMMM d',
  )}`.toUpperCase();
};

const createDeadlineText = (deadlineDate: Date) => {
  // Deadline is at midnight PST
  // This is the deadline without
  // the three hour buffer.
  return format(addHours(deadlineDate, -3), 'EEEE, MMMM do');
};

const calendarMonthFormatter = (date: Date) =>
  format(date, 'MMM').toUpperCase();

interface IProps {
  initialValue?: string;
  dateBlocks?: RiskPeriodDateBlock[];
  onSelect: (
    riskPeriod: ICalculatorInput[CalculatorStepValue.SelectRiskPeriod],
  ) => void;
}

export function RiskPeriodBlockDates({
  dateBlocks,
  initialValue,
  onSelect,
}: IProps) {
  const { control, watch } = useForm({
    defaultValues: {
      dateRange: initialValue || undefined,
    },
  });

  const dateRangeChange = watch('dateRange');

  useEffect(() => {
    const newRiskPeriod = dateRangeChange
      ? JSON.parse(dateRangeChange)
      : undefined;

    if (newRiskPeriod && Array.isArray(newRiskPeriod)) {
      onSelect(newRiskPeriod as [string, string, string?]);
    }
  }, [dateRangeChange, onSelect]);

  return (
    <Box>
      <Box component="form">
        <Controller
          control={control}
          name="dateRange"
          render={({ field }) => (
            <RadioGroup
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                gridAutoRows: '1fr',
                gap: 2,
              }}
              {...field}
              // Keep typing happy and prevent undefined from
              // creating uncontrolled inputs error by
              // defaulting to an empty string
              value={field.value || ''}
            >
              {dateBlocks?.map((block) => {
                const startDate = new Date(block.startDate);
                const endDate = new Date(block.endDate);
                const deadlineDate = new Date(block.deadlineDate);

                const deadlineLabel = createDeadlineText(deadlineDate);
                const label = createLabel(startDate, endDate);
                const value = convertDateRangeToJSON([
                  startDate,
                  endDate,
                  deadlineDate,
                ]);

                return (
                  <RadioTile
                    key={value}
                    id={`${value}-input-radio`}
                    label={label}
                    svg={
                      <CalendarTodayIconWithText
                        sx={{ p: 2, fill: 'currentColor', fillOpacity: 0.85 }}
                      >
                        {calendarMonthFormatter(startDate)}
                      </CalendarTodayIconWithText>
                    }
                    value={value}
                  >
                    <ProgramTileInfo
                      programDescription=""
                      deadlineInfo={deadlineLabel || ''}
                    />
                  </RadioTile>
                );
              })}
            </RadioGroup>
          )}
        />
      </Box>
    </Box>
  );
}
