import { Box, InputLabel, Typography } from '@mui/material';

interface IProps {
  children: React.ReactNode;
  label: string;
}

export function InfoBox({ children, label }: IProps) {
  return (
    <Box mb={2}>
      <InputLabel sx={{ fontSize: '0.875rem' }}>
        {label}
        <Typography minHeight="1rem" color="common.black">
          {children}
        </Typography>
      </InputLabel>
    </Box>
  );
}
