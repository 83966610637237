import { Box, Typography } from '@mui/material';

import { useQuoteCalculator } from '../../hooks';
import { QuoteCalculatorLayout } from '../QuoteCalculator';

export function SelectOptionTerms() {
  const { input } = useQuoteCalculator();

  const selectedInput = { optionsTerm: '$10,000' };

  return (
    <QuoteCalculatorLayout input={selectedInput} title="Select an Options Term">
      <Box>
        <Typography>What did you select: {input?.optionsTerm}</Typography>
      </Box>
    </QuoteCalculatorLayout>
  );
}
