import { configureStore } from '@reduxjs/toolkit';

import { api } from './api';
import { arbolGeojsonApi } from './arbolGeojson';
import { authSlice } from './auth';
import { bingMapApi } from './bingMapApi';
import { calculatorSlice } from './calculator';
import { registrationSlice } from './registration';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [arbolGeojsonApi.reducerPath]: arbolGeojsonApi.reducer,
    [authSlice.name]: authSlice.reducer,
    [bingMapApi.reducerPath]: bingMapApi.reducer,
    [calculatorSlice.name]: calculatorSlice.reducer,
    [registrationSlice.name]: registrationSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(arbolGeojsonApi.middleware)
      .concat(bingMapApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
