import { Box } from '@mui/material';

export function Footer() {
  return (
    <Box
      sx={{
        backgroundImage: 'linear-gradient(180deg,#242424,#101010 50%)',
        display: 'flex',
        gridArea: 'footer',
        py: 5,
        px: 2,
      }}
    >
      Footer
    </Box>
  );
}
