import { booleanPointInPolygon, polygon } from '@turf/turf';
import { GeoJSON as GeoJSONType } from 'geojson';
import { LatLngTuple } from 'leaflet';

import { config } from '../../config/config';

const mapUrl = config.map.mapboxUrl;
const REPLACE_KEY = '{id}';

export const getUrlByMapStyleId = (mapStyleId: string) =>
  mapUrl.replace(REPLACE_KEY, mapStyleId);

/**
 * LEAFLET TO DATASET GRID HELPERS
 * ===============================
 * These functions are used to help Leaflet and Dataset Grids interact.
 */

/**
 * @description Converts the LatLngTuple to grid coordinates (x,y) of a dataset
 *
 * e.g. LatLng to PRISM or CPC grid
 */
export function convertLatLngToGridCoordinates(
  latlng: LatLngTuple,
  step: number,
  offset = 0,
  snapToGrid = true,
): [number, number] {
  const [lat, lng] = latlng;
  const halfStep = step / 2;

  const y = (lat - offset + halfStep) / step;
  const x = (lng - offset + halfStep) / step;

  return [snapToGrid ? Math.floor(x) : x, snapToGrid ? Math.floor(y) : y];
}

/**
 * @description Converts the grid coordinates (x,y) to LatLng
 */
export function convertCoordsToLatLng(
  coords: [number, number],
  step: number,
  offset = 0,
): LatLngTuple {
  const [x, y] = coords;
  const halfStep = step / 2;
  return [step * y + offset - halfStep, step * x + offset - halfStep];
}

/**
 * @description takes a grid centerpoint (x,y) and creates a latlng bounding box
 */
export function createRectangleBounds(
  centerPoint: [number, number],
  step: number,
  offset: number,
): LatLngTuple[] {
  const [latMin, lngMin] = convertCoordsToLatLng(centerPoint, step, offset);

  const latMax = latMin + step;
  const lngMax = lngMin + step;

  return [
    [latMin, lngMin],
    [latMax, lngMax],
  ];
}

/**
 * LEAFLET & TURF MAP HELPERS
 * =========================
 * These functions are used to help with the Leaflet and Turf map
 *
 */

/**
 * @description Checks if a point is inside a geojson
 * by iterating over the geojson features
 * and checking if the point is inside the feature
 */
export function isPointInGeoJSON(
  point: [number, number],
  geoJson: GeoJSONType,
) {
  if ('features' in geoJson) {
    return geoJson.features.some((feature: any) => {
      return booleanPointInPolygon(point, feature);
    });
  }

  return false;
}

/**
 * Takes a rectangle bounds for leaflet and returns a polygon in turf
 *
 * Leaflet uses [lat, lng]
 * turf uses [lng, lat]
 * This swaps the coordinates
 * before creating polygon
 */
export function getPolygonFromLeafletRectBounds(bounds: LatLngTuple[]) {
  const [bottomLeft, topRight] = bounds;
  const [bottom, left] = bottomLeft;
  const [top, right] = topRight;

  return polygon([
    [
      [left, bottom],
      [left, top],
      [right, top],
      [right, bottom],
      [left, bottom],
    ],
  ] as any);
}

/**
 * HELPERS FOR MAP LOCATION SELECTOR
 * ===============================
 * These functions are used to help with the Map Location Selector Components
 *
 */

export function debugPathColorOptions(active: boolean) {
  return active
    ? {
        color: 'green',
        fillColor: 'green',
        opacity: 0.5,
        fillOpacity: 0.25,
        weight: 1,
      }
    : {
        color: 'grey',
        fillColor: 'grey',
        opacity: 0.5,
        fillOpacity: 0.25,
        weight: 1,
      };
}
