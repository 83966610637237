import { Box, MenuItem, Select } from '@mui/material';
import { useEffect, useMemo } from 'react';

import { DATA_COVERAGE_AREA, MapGridDatasetOption } from '../../@types/mapping';

import { LEAFLET_POSITION_CLASSES } from './constants';

interface IProps {
  currentDataSet: MapGridDatasetOption;
  datasetOptions: MapGridDatasetOption[];
  isInsideUSA: boolean;
  onDatasetChange: (dataset: MapGridDatasetOption) => void;
  position: keyof typeof LEAFLET_POSITION_CLASSES;
}

export function MapDatasetSelector({
  currentDataSet,
  datasetOptions,
  isInsideUSA,
  onDatasetChange,
  position,
}: IProps) {
  /**
   * Filters the dataset options based on the current map view.
   * Removes options that are USA only and/or outside of the USA only.
   */
  const filteredDatasetOptions = useMemo(
    () =>
      datasetOptions.filter((dataset) => {
        if (dataset.dataCoverage === DATA_COVERAGE_AREA.GLOBAL) {
          return true;
        }

        if (
          isInsideUSA &&
          dataset.dataCoverage === DATA_COVERAGE_AREA.INSIDE_US
        ) {
          return true;
        }

        if (
          !isInsideUSA &&
          dataset.dataCoverage === DATA_COVERAGE_AREA.OUTSIDE_US
        ) {
          return true;
        }

        return false;
      }),
    [datasetOptions, isInsideUSA],
  );

  const hasCurrentSelection = useMemo(
    () =>
      filteredDatasetOptions.some(
        (dataset) => currentDataSet.name === dataset.name,
      ),
    [currentDataSet.name, filteredDatasetOptions],
  );

  /**
   * Checks if the current selected option still exists
   * and if not, selects the first option.
   */
  useEffect(() => {
    if (!hasCurrentSelection) {
      onDatasetChange(filteredDatasetOptions[0]);
    }
  }, [
    currentDataSet.name,
    filteredDatasetOptions,
    hasCurrentSelection,
    onDatasetChange,
  ]);

  return (
    <Box
      minWidth={100}
      className={LEAFLET_POSITION_CLASSES[position]}
      padding={1}
    >
      <Select
        sx={{ bgcolor: 'white', pointerEvents: 'auto' }}
        fullWidth
        onChange={(event) => {
          const newDataset = datasetOptions.find(
            (dataset) => event.target.value === dataset.name,
          );

          if (newDataset) {
            onDatasetChange(newDataset);
          }
        }}
        value={hasCurrentSelection ? currentDataSet.name : ''}
      >
        {filteredDatasetOptions.map((dataset) => (
          <MenuItem value={dataset.name} key={dataset.name}>
            {dataset.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
