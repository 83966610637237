import { differenceInDays } from 'date-fns';
import { z } from 'zod';

import i18n from '../../../config/i18n';
import {
  formatDateRange,
  getTotalMonthsBetweenDateRange,
} from '../../../helpers';

import { MAX_DURATION_MONTHS, MIN_DURATION_DAYS } from './constants';

export function getXSRDateRangeSchema() {
  return z
    .object({
      dateRange: z.array(z.date(), z.date()),
    })
    .refine(
      ({ dateRange }) => {
        const [startDate, endDate] = dateRange;
        return differenceInDays(endDate, startDate) >= MIN_DURATION_DAYS;
      },
      ({ dateRange }) => ({
        message: i18n.t(
          'validation.xsrDateRange.minDuration',
          'Start and end date must be at least {{minDuration}} days apart to be valid, currently {{formattedDuration}} apart.',
          {
            minDuration: MIN_DURATION_DAYS.toString(),
            formattedDuration: formatDateRange(dateRange[1], dateRange[0]),
          },
        ),
        path: ['dateRange'],
      }),
    )
    .refine(
      ({ dateRange }) => {
        const [startDate, endDate] = dateRange;
        return (
          getTotalMonthsBetweenDateRange(endDate, startDate) <=
          MAX_DURATION_MONTHS
        );
      },
      ({ dateRange }) => ({
        message: i18n.t(
          'validation.xsrDateRange.maxDuration',
          `Start and end date must not be greater than {{maxDuration}} months apart to be valid, current selection is {{formattedDuration}}.`,
          {
            maxDuration: MAX_DURATION_MONTHS.toString(),
            formattedDuration: formatDateRange(dateRange[1], dateRange[0]),
          },
        ),
        path: ['dateRange'],
      }),
    );
}
