import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button';
import { HorizontalRuler } from '../HorizontalRuler';

interface IProps {
  canBack?: boolean;
  canCancel?: boolean;
  nextDisabled?: boolean;
  handleCancelClick: () => void;
  handleNextClick: () => void;
  handlePreviousClick: () => void;
}

export function QuoteCalculatorFooter({
  canBack,
  canCancel,
  nextDisabled,
  handleCancelClick,
  handleNextClick,
  handlePreviousClick,
}: IProps) {
  const { t } = useTranslation();

  return (
    <Box data-testid="quote-calculator-footer" mt={3}>
      <HorizontalRuler />
      <Box mt={2} display="flex" justifyContent="space-between">
        <Stack direction="row" spacing={3}>
          {canBack && (
            <Button onClick={handlePreviousClick}>
              {t('common.actions.back', 'Back')}
            </Button>
          )}
          {canCancel && (
            <Button variant="outlined" onClick={handleCancelClick}>
              {t('common.actions.cancel', 'Cancel')}
            </Button>
          )}
        </Stack>
        <Box>
          <Button disabled={nextDisabled} onClick={handleNextClick}>
            {t('common.actions.next', 'Next')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
