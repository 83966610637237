import { LatLngTuple } from 'leaflet';
import { useMemo } from 'react';
import { Polyline } from 'react-leaflet';

import {
  convertCoordsToLatLng,
  convertLatLngToGridCoordinates,
} from './helpers';

interface IProps {
  step: number;
  offset: number;
  zoom: number;
}

export function DebugGrid({ step, offset, zoom }: IProps) {
  const maxLatLng: [number, number] = [180, 720];

  const [maxGridX, maxGridY] = convertLatLngToGridCoordinates(
    maxLatLng,
    step,
    offset,
  );

  const gridLines = useMemo(() => {
    const verticalLines = Array(maxGridX)
      .fill(null)
      .map((_, index) => {
        const x = index - maxGridX / 2;

        const latLng = convertCoordsToLatLng([x, 0], step, offset);
        return [
          [-90, latLng[1]],
          [90, latLng[1]],
        ] as LatLngTuple[];
      });

    const horizontalLines = Array(maxGridY)
      .fill(null)
      .map((_, index) => {
        const y = index - maxGridY / 2;

        const latLng = convertCoordsToLatLng([0, y], step, offset);
        return [
          [latLng[0], -360],
          [latLng[0], 360],
        ] as LatLngTuple[];
      });

    return horizontalLines.concat(verticalLines);
  }, [maxGridX, maxGridY, offset, step]);

  return (
    <Polyline
      positions={gridLines}
      pathOptions={{ opacity: zoom / 10, weight: zoom / 18 }}
    />
  );
}
