import { Box } from '@mui/material';
import { DomEvent, latLngBounds, popup } from 'leaflet';
import { useCallback, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';

import { IResource } from '../../store/bingMapApi/interfaces';
import { LocationAutocomplete } from '../LocationAutocomplete';

import { LEAFLET_POSITION_CLASSES } from './constants';

export function LocationMapSearch() {
  const map = useMap();
  const ref = useRef(null);

  // Disable click event firing when select search input is clicked
  useEffect(() => {
    if (ref.current) {
      DomEvent.disableClickPropagation(ref.current);
    }
  }, []);

  const onLocationSearch = useCallback(
    (location: IResource) => {
      if (map) {
        // Zoom out/in to fit selected search location
        const bbox = location.bbox;
        const bounds = latLngBounds([bbox[0], bbox[1]], [bbox[2], bbox[3]]);
        map.fitBounds(bounds);

        // open pop-up at selected search location center
        const center = location.point.coordinates;
        const popupInstance = popup()
          .setLatLng({
            lat: center[0],
            lng: center[1],
          })
          .setContent(location.name);
        map.openPopup(popupInstance);
      }
    },
    [map],
  );

  return (
    <Box
      ref={ref}
      className={LEAFLET_POSITION_CLASSES['topleft']}
      sx={{ padding: 1, pointerEvents: 'auto' }}
    >
      <LocationAutocomplete onSelected={onLocationSearch} />
    </Box>
  );
}
