import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Role } from '../../../graphql/generated';

import { NavLink } from './NavLink';

interface IProps {
  roleType?: Role | `${Role}` | null;
}

export function Nav({ roleType = 'agent' }: IProps) {
  const { t } = useTranslation();

  const isAgent = roleType === 'agent';

  return (
    <Box
      sx={{
        bgcolor: 'secondary.main',
        color: 'common.white',
        gridArea: 'nav',
      }}
    >
      {isAgent && (
        <NavLink to="/calculator">
          {t('nav.links.calculator', 'Create A Quote')}
        </NavLink>
      )}
      {isAgent && (
        <NavLink to="/apply">
          {t('nav.links.apply', 'Request Trade Execution')}
        </NavLink>
      )}
      {isAgent && (
        <NavLink to="/quotes">{t('nav.links.quotes', 'Saved Quotes')}</NavLink>
      )}
      <NavLink to="/contracts">{t('nav.links.contracts', 'Contracts')}</NavLink>
      <NavLink to="/account">{t('nav.links.account', 'Account')}</NavLink>
      <NavLink to="/notices">
        {t('nav.links.notices', 'Agreements and Notices')}
      </NavLink>
      <NavLink to="/contact">{t('nav.links.help', 'Help')}</NavLink>
    </Box>
  );
}
