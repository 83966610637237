import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { UnprotectedLayout } from '../../components/UnprotectedLayout';

interface IProps {
  email: string;
}

export function SignupAccountCreatedPage({ email }: IProps) {
  const { t } = useTranslation();

  return (
    <UnprotectedLayout
      title={t('pages.signupAccountCreated.title', 'Account Created!')}
    >
      <Stack spacing={4} maxWidth="300px" marginY={4} textAlign="center">
        <Typography display="block">
          {t(
            'pages.signupAccountCreated.accountcreated',
            'Your account was successfully created.',
          )}
        </Typography>
        <Typography display="block">
          {t(
            'pages.signupAccountCreated.emailsent',
            'An email has been sent to',
          )}
          <Typography color="primary" component="span">
            &nbsp; {email}
          </Typography>
          .
        </Typography>
        <Typography display="block">
          {t(
            'pages.signupAccountCreated.verifyInstructions',
            'To verify your account and login, please click the link in the email.',
          )}
        </Typography>
      </Stack>
    </UnprotectedLayout>
  );
}
