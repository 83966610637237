import { useAuth } from '../../hooks';

import { MainLayout } from './MainLayout';

export function MainLayoutContainer() {
  const { logout, user } = useAuth();

  if (!user) {
    throw new Error('User is not defined in a protected route');
  }

  return <MainLayout onLogOutPressed={logout} currentUser={user} />;
}
