import { z } from 'zod';

import i18n from '../../config/i18n';

export function getSignupAccountDetailsPageSchema() {
  return z
    .object({
      firstName: z
        .string()
        .min(
          1,
          i18n.t('validation.firstName.required', 'first name is required'),
        ),
      lastName: z
        .string()
        .min(
          1,
          i18n.t('validation.lastName.required', 'last name is required'),
        ),
      companyName: z.ostring(),
      activationCode: z.ostring(),
      country: z
        .string()
        .min(2, i18n.t('validation.country.required', 'country is required')),
      address1: z
        .string()
        .min(1, i18n.t('validation.address.required', 'address is required')),
      city: z
        .string()
        .min(1, i18n.t('validation.city.required', 'city is required')),
      state: z.ostring(),
      zipcode: z.ostring(),
    })
    .refine(
      (data) => (data.country === 'US' ? data.zipcode && data.state : true),
      {
        message: i18n.t(
          'validation.statezipcode.required',
          'zipcode is required',
        ),
        path: ['zipcode'],
      },
    );
}

export type ISignupAccountDetailsPageSchema = z.infer<
  ReturnType<typeof getSignupAccountDetailsPageSchema>
>;
