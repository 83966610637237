import { Box, styled } from '@mui/material';
import { NavLink as ReactRouterNavLink, NavLinkProps } from 'react-router-dom';

const colorActive = 'common.white';
const colorInactive = 'common.grey';
const colorHover = 'common.lightGrey';

const filterActive = 'brightness(1.8)';
const filterHover = 'brightness(1.4)';

const StyledNavLink = styled(ReactRouterNavLink)`
  display: inline-block;
  text-decoration: none;
  width: 100%;
`;

export function NavLink({ children, ...props }: NavLinkProps) {
  return (
    <Box mb={0.5}>
      <StyledNavLink {...props}>
        {({ isActive }) => (
          <Box
            sx={{
              backgroundColor: 'secondary.main',
              filter: isActive ? filterActive : 'none',
              color: isActive ? colorActive : colorInactive,
              padding: 2,
              textDecoration: 'none',
              transitionProperty: 'color, filter',
              transitionDuration: '0.5s',
              transitionTimingFunction: 'ease',

              ':hover': {
                filter: isActive ? filterActive : filterHover,
                color: colorHover,
              },
            }}
          >
            {children}
          </Box>
        )}
      </StyledNavLink>
    </Box>
  );
}
