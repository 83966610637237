import { GridSortModel } from '@mui/x-data-grid';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InnerContainerLoader } from '../../components/Loaders';
import {
  PaginationInputs,
  Quote,
  SortDirection,
} from '../../graphql/generated';
import { useGetQuotesQuery } from '../../store/api';

import { QuotesPage } from './QuotesPage';
import { rowFormatter, sortByMap } from './helpers';

export function QuotesPageContainer() {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(10);
  const [sortBy, setSortBy] = useState<string>(sortByMap['location']);
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Desc,
  );
  const [search, setSearch] = useState<string>('');
  const input: PaginationInputs = useMemo(
    () => ({
      page,
      limit,
      sortBy,
      sortDirection,
      search,
    }),
    [page, limit, sortBy, sortDirection, search],
  );

  const { data, isLoading, isFetching } = useGetQuotesQuery({ input });

  const handlePageChange = useCallback((page: number) => {
    // dataGrid page prop is zero index-based while API page input accepts >= 1
    setPage(Math.max(page + 1, 1));
  }, []);

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchedVal = event.target.value;
      setSearch(searchedVal);
    },
    [],
  );

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      const sortField = sortModel[0].field as keyof typeof sortByMap;
      const formattedSortField = sortByMap[sortField];
      const formattedSortDir = sortModel[0].sort || sortDirection;
      const sortDir =
        SortDirection.Asc === formattedSortDir.toUpperCase()
          ? SortDirection.Asc
          : SortDirection.Desc;

      setSortBy(formattedSortField);
      setSortDirection(sortDir);
    },
    [sortDirection],
  );

  const formattedRows = useMemo(() => {
    const quotes: Quote[] = data?.getQuotes?.items || [];
    return rowFormatter(quotes);
  }, [data]);

  if (isLoading) {
    return (
      <InnerContainerLoader
        message={t('common.actions.loading', 'Loading ...')}
      />
    );
  }

  return (
    <QuotesPage
      handleSearch={handleSearch}
      handleSortModelChange={handleSortModelChange}
      isLoading={isFetching}
      onPageChange={handlePageChange}
      page={page - 1}
      pageSize={limit}
      quotes={formattedRows}
      rowCount={data?.getQuotes?.count || 0}
      search={search}
    />
  );
}
