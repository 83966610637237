import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GeoJSON as GeoJSONType } from 'geojson';

export const getCountriesGeoJsonPathName = () => 'countries-geo.json';
export const getStatesGeoJsonPathName = () => 'states-geo.json';

export const arbolGeojsonApi = createApi({
  reducerPath: 'arbolGeojson',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://arbol-geojson.s3.amazonaws.com/',
  }),
  endpoints: (builder) => ({
    getCountriesGeoJSON: builder.query<GeoJSONType, null>({
      query: getCountriesGeoJsonPathName,
    }),
    getStatesGeoJSON: builder.query<GeoJSONType, null>({
      query: getStatesGeoJsonPathName,
    }),
  }),
});

export const { useGetCountriesGeoJSONQuery, useGetStatesGeoJSONQuery } =
  arbolGeojsonApi;
