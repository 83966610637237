import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLazyQueryBingGeocoderQuery } from '../../store/bingMapApi';
import { IResource } from '../../store/bingMapApi/interfaces';

interface IProps {
  placeholder?: string;
  onSelected?: (selected: IResource) => void;
}

export function LocationAutocomplete({ placeholder, onSelected }: IProps) {
  const { t } = useTranslation();
  const [lazyQuery, { isLoading }] = useLazyQueryBingGeocoderQuery();
  const [value, setValue] = useState<IResource | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<IResource[]>([]);

  const lazyQueryThrottled = useMemo(
    () =>
      throttle((query: string) => lazyQuery(query, true), 50, {
        trailing: false,
        leading: true,
      }),
    [lazyQuery],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    lazyQueryThrottled(inputValue)?.then((results) => {
      if (active) {
        let newOptions: IResource[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results?.data?.resourceSets?.[0]?.resources) {
          const fetchedOptions = results?.data?.resourceSets?.[0]?.resources;

          newOptions = [...newOptions, ...(fetchedOptions || [])];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, lazyQueryThrottled, value]);

  return (
    <Autocomplete
      id="bing-map-geocoder-service"
      data-testid="bing-map-geocoder-service"
      autoComplete
      filterOptions={(x) => x}
      filterSelectedOptions
      getOptionLabel={(option) => option.name}
      includeInputInList
      loading={isLoading}
      options={options}
      sx={{ width: 300 }}
      value={value}
      onChange={(event: any, newValue: IResource | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);

        if (newValue && onSelected) {
          onSelected(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            placeholder ||
            t('common.actions.searchLocation', 'Search for a location')
          }
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        // Highlight text
        const matches = match(option.name, inputValue);
        const parts = parse(option.name, matches);

        // eslint-disable-next-line react/prop-types
        const key = `${props.id}-${option.name}`;

        return (
          <li {...props} key={key}>
            <Grid container alignItems="center">
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
