import { Box, Typography } from '@mui/material';
import { Map, TileLayer as LeafletTileLayer } from 'leaflet';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  MapContainer,
  Rectangle,
  TileLayer,
  useMap,
  useMapEvent,
} from 'react-leaflet';

import {
  LEAFLET_POSITION_CLASSES,
  MapStyleId,
  TileMapDefaultProps,
  MapStyleIdLabels,
} from './constants';
import { getUrlByMapStyleId } from './helpers';

const defaultOptions = {
  ...TileMapDefaultProps,
  id: MapStyleId.satellite,
};

const BOUNDS_STYLE = { weight: 1 };

/**
 * Creates the smaller reactangle that is used
 * to show the current view of the map.
 *
 * Also attaches the click event on the mini map
 */
function MinimapBounds({
  onClick,
  parentMap,
  zoom,
}: {
  parentMap: Map;
  zoom: number;
  onClick: () => void;
}) {
  const minimap = useMap();

  // Keep track of bounds in state to trigger renders
  const [bounds, setBounds] = useState(parentMap.getBounds());

  const onChange = useCallback(() => {
    setBounds(parentMap.getBounds());
    // Update the minimap's view to match the parent map's center and zoom
    minimap.setView(parentMap.getCenter(), zoom);
  }, [minimap, parentMap, zoom]);

  useMapEvent('click', (event) => {
    event.originalEvent.stopPropagation();
    onClick();
  });

  useEffect(() => {
    parentMap.on('move', onChange);
    parentMap.on('zoom', onChange);

    return () => {
      parentMap.off('move', onChange);
      parentMap.off('zoom', onChange);
    };
  }, [onChange, parentMap]);

  return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />;
}

interface IProps {
  mapType: string;
  onClick: () => void;
  position: keyof typeof LEAFLET_POSITION_CLASSES;
  zoom?: number;
}

export function MinimapControl({ onClick, mapType, position, zoom }: IProps) {
  const tileLayerRef = useRef<LeafletTileLayer>(null);
  const parentMap = useMap();
  const mapZoom = zoom || 2;

  useEffect(() => {
    if (tileLayerRef.current) {
      const newTileUrl = getUrlByMapStyleId(mapType);
      tileLayerRef.current.setUrl(newTileUrl);
    }
  }, [mapType]);

  const label = MapStyleIdLabels[mapType];

  // Memoize the minimap so it's not affected by position changes
  const minimap = useMemo(
    () => (
      <MapContainer
        attributionControl={false}
        center={parentMap.getCenter()}
        doubleClickZoom={false}
        dragging={false}
        scrollWheelZoom={false}
        style={{ height: 80, width: 80 }}
        zoom={mapZoom}
        zoomControl={false}
      >
        <Box
          alignItems="center"
          bottom={0}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          left={0}
          padding={1}
          position="absolute"
          right={0}
          top={0}
          zIndex={1000}
        >
          <Typography
            color="white"
            fontSize="0.9rem"
            fontWeight={700}
            sx={{ textShadow: '0px 0px 2px rgba(0, 0, 0, 0.75)' }}
          >
            {label}
          </Typography>
        </Box>
        <TileLayer ref={tileLayerRef} {...defaultOptions} />
        <MinimapBounds parentMap={parentMap} zoom={mapZoom} onClick={onClick} />
      </MapContainer>
    ),
    [label, mapZoom, onClick, parentMap],
  );

  const positionClass =
    (position && LEAFLET_POSITION_CLASSES[position]) ||
    LEAFLET_POSITION_CLASSES.topright;

  return (
    <Box className={positionClass}>
      <Box className="leaflet-control leaflet-bar">{minimap}</Box>
    </Box>
  );
}
