import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRegistration } from '../../hooks';
import { useRegisterUserMutation } from '../../store/api';

import { SignupAccountDetailsPage } from './SignupAccountDetailsPage';
import { ISignupAccountDetailsPageSchema } from './SignupAccountDetailsPage.schema';

export function SignupAccountDetailsPageContainer() {
  const navigate = useNavigate();
  const { data: storeData, reset } = useRegistration();
  const [mutate, { isLoading, isError }] = useRegisterUserMutation();

  // redirect user if they haven't
  // completed the first step
  useEffect(() => {
    const { email, password, phone } = storeData;

    if (!email || !password || !phone) {
      navigate('/');
    }
  }, [navigate, storeData]);

  const onSubmit = async (data: ISignupAccountDetailsPageSchema) => {
    const isUSA = data.country === 'US';

    const results = await mutate({
      input: {
        address1: data.address1,
        city: data.city,
        companyName: data.companyName,
        country: data.country,
        email: storeData.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: storeData.password,
        phone: storeData.phone,
        state: isUSA ? data.state : undefined,
        zipcode: isUSA ? data.zipcode : undefined,
      },
    });

    if ('data' in results && results.data.registerUser) {
      navigate('/register/success', {
        replace: true,
        state: { email: storeData.email },
      });
      reset();
    }

    if ('error' in results) {
      console.error(results.error);
    }
  };

  const errorMessage = isError
    ? 'Internal Server Error. Please try again.' // TODO: i18n
    : undefined;

  return (
    <SignupAccountDetailsPage
      onSubmit={onSubmit}
      isLoading={isLoading}
      errorMessage={errorMessage}
    />
  );
}
