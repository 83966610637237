import { Box, CircularProgress } from '@mui/material';

interface IProps {
  message?: string;
}

export function InnerContainerLoader({ message }: IProps) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        padding: 4,
        width: '100%',
      }}
    >
      <CircularProgress size="5rem" />
      <Box mt={2}>{message}</Box>
    </Box>
  );
}
