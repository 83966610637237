export enum DATA_COVERAGE_AREA {
  OUTSIDE_US = 'outsideUS',
  INSIDE_US = 'insideUS',
  GLOBAL = 'global',
}

export type MapGridDatasetOption = {
  dataCoverage: DATA_COVERAGE_AREA;
  label: string;
  name: string;
  offset: number;
  step: number;
};

export type MapGridDatasetKeys =
  | 'CHIRPS_25'
  | 'CHIRPS_5'
  | 'PRISM'
  | 'PRISM_PRECIP_30'
  | 'PRISM_PRECIP_DAILY'
  | 'CPC_US'
  | 'CPC_TEMP';
