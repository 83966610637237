import { IResource } from '../store/bingMapApi/interfaces';

import { getStateByCode } from './mappings';

const UNITED_STATES_KEY = 'United States';

export function formatAddress(address: IResource['address']) {
  const country = address?.countryRegion;
  const state =
    country === UNITED_STATES_KEY
      ? getStateByCode(address?.adminDistrict)
      : address?.adminDistrict;

  return `${state}, ${country}`;
}
