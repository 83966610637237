import { useQuoteCalculator } from '../../hooks';
import { CalculatorStepValue } from '../../store/calculator/constants';
import { PageTitle } from '../PageTitle';
import {
  SelectClient,
  SelectLocation,
  SelectOptionTerms,
  SelectProgram,
  SelectRiskPeriod,
  SummaryReview,
} from '../QuoteCalculatorScreens';
import { SelectIndustry } from '../QuoteCalculatorScreens/SelectIndustry';

function InvalidStep() {
  return <PageTitle textAlign="center">Invalid Step</PageTitle>;
}

export function QuoteCalculatorContainer() {
  const { activeStep } = useQuoteCalculator();

  console.log('activeStep: ', activeStep.value);

  let CalculatorStepContent: () => JSX.Element;

  switch (activeStep.value) {
    case CalculatorStepValue.SelectIndustry:
      CalculatorStepContent = SelectIndustry;
      break;
    case CalculatorStepValue.SelectProgram:
      CalculatorStepContent = SelectProgram;
      break;
    case CalculatorStepValue.SelectRiskPeriod:
      CalculatorStepContent = SelectRiskPeriod;
      break;
    case CalculatorStepValue.SelectLocation:
      CalculatorStepContent = SelectLocation;
      break;
    case CalculatorStepValue.SelectOptionsTerm:
      CalculatorStepContent = SelectOptionTerms;
      break;
    case CalculatorStepValue.SelectClient:
      CalculatorStepContent = SelectClient;
      break;
    case CalculatorStepValue.Review:
      CalculatorStepContent = SummaryReview;
      break;
    default:
      // should never happen
      CalculatorStepContent = InvalidStep;
  }

  return <CalculatorStepContent />;
}
