import { useQuoteCalculator } from '../../hooks';

import { QuoteStepper } from './QuoteStepper';

export function QuoteStepperContainer() {
  const { activeStepIndex, completedSteps, steps, setStep, input } =
    useQuoteCalculator();

  return (
    <QuoteStepper
      activeStepIndex={activeStepIndex}
      completedSteps={completedSteps}
      onStepClick={setStep}
      steps={steps}
      input={input}
    />
  );
}
