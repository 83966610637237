import { ITileMappings } from '../../components/RadioButtons';
import { GRPIcon, XSRIcon } from '../../components/SVGs';
import i18n from '../../config/i18n';

import { Industry } from './industriesSelectMappings';

export enum Program {
  GRP = 'GRP',
  XSR = 'XSR',
}

type ProgramMappings = ITileMappings<Program>;

// need to use i18n keys for labels
export function getAgriculturePrograms(): ProgramMappings[] {
  return [
    {
      icon: <GRPIcon />,
      disabled: false,
      label: i18n.t('labels.program.grp.title', 'GuaRAINteed Program (GRP)'),
      value: Program.GRP,
      extra: {
        description: i18n.t(
          'labels.program.grp.description',
          'Low Precipitation',
        ),
        extraInfo: i18n.t(
          'labels.program.grp.deadline',
          '10th of Month before Risk Period',
        ),
      },
    },
    {
      icon: <XSRIcon />,
      label: i18n.t('labels.program.xsr.title', 'XsRAIN Program (XSR)'),
      value: Program.XSR,
      extra: {
        description: i18n.t(
          'labels.program.xsr.description',
          'High Precipitation',
        ),
        extraInfo: i18n.t(
          'labels.program.xsr.deadline',
          '20 days before Risk Period',
        ),
      },
    },
  ];
}

export const programMappingGetters = {
  [Industry.Agriculture]: getAgriculturePrograms,
  [Industry.Energy]: () => [],
};
