import { z } from 'zod';

import i18n from '../../config/i18n';

export function getChangePasswordPageSchema() {
  return z
    .object({
      currentPassword: z.string(),
      newPassword: z
        .string()
        .min(1, i18n.t('validation.password.required'))
        .min(8, i18n.t('validation.password.min')),
      confirmPassword: z
        .string()
        .min(1, i18n.t('validation.password.required'))
        .min(
          8,
          i18n.t(
            'validation.password.min',
            'password requires minimum 8 characters',
          ),
        ),
    })
    .refine((data) => data.newPassword === data.confirmPassword, {
      message: i18n.t('validation.password.match'),
      path: ['confirmPassword'],
    })
    .refine((data) => data.currentPassword !== data.newPassword, {
      message: i18n.t(
        'validation.password.different',
        'new password must be different than current password',
      ),
      path: ['confirmPassword'],
    });
}

export type IChangePasswordPageSchema = z.infer<
  ReturnType<typeof getChangePasswordPageSchema>
>;
