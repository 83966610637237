export function EnergyIcon() {
  return (
    <svg
      height="100%"
      width="100%"
      viewBox="0 0 150 112"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M96.63.157a2.5 2.5 0 0 1 2.494 2.335l.005.165v.097h6.5a4.5 4.5 0 0 1 4.495 4.288l.005.212v2.41c22.834 5.56 39.264 26.12 39.264 50.038 0 28.442-23.057 51.5-51.5 51.5-16.692 0-31.713-7.996-41.168-20.542l2.936 14.923h6.943a2.5 2.5 0 0 1 .165 4.994l-.165.006H3.765a2.5 2.5 0 0 1-.164-4.995l.164-.005h6.58l.06-.117.068-.115.512-.832 12.628-64.207H5v5.794a2.5 2.5 0 0 1-2.336 2.495l-.164.005a2.5 2.5 0 0 1-2.495-2.335L0 46.106v-8.204a2.5 2.5 0 0 1 .747-1.783l.135-.123.383-.325v-.359h.423l23.3-19.78c.232-.197.481-.342.738-.44v-.155l.738-.001c.09-.005.178-.005.267 0h16.975c.089-.005.178-.005.267 0l.67.001v.13c.225.078.445.192.652.343l.153.121 23.301 19.78.355.001v.3l.451.384.135.123a2.5 2.5 0 0 1 .739 1.582l.008.2v8.205l-.005.165a2.5 2.5 0 0 1-2.33 2.33l-.165.005-.164-.005a2.5 2.5 0 0 1-2.33-2.33l-.006-.165v-5.794H46.823l6.284 31.947c5.522 19.775 23.674 33.943 44.786 33.943 25.682 0 46.5-20.819 46.5-46.5 0-21.18-14.27-39.445-34.263-44.875v2.408a4.5 4.5 0 0 1-4.289 4.496l-.212.004h-6.5v.098a2.5 2.5 0 0 1-4.994.164l-.006-.164v-2.588h-4.916a2.5 2.5 0 0 1-.164-4.995l.164-.005h4.916v-4h-4.916a2.5 2.5 0 0 1-.164-4.995l.164-.005h4.916V2.657a2.5 2.5 0 0 1 2.5-2.5ZM35.032 75.073l-18.829 30.51h37.332l-18.503-30.51Zm10.261-16.626-7.311 11.847 14.27 23.53L48.234 73.4a51.45 51.45 0 0 1-1.078-4.823 2.5 2.5 0 0 1-.017-.75l-1.845-9.38Zm-20.199.238-6.631 33.717L32.121 70.27l-7.026-11.586Zm89.174-23.083a1 1 0 0 1 1 1v5.412a1 1 0 0 1-1 1H89.361V55.39h21.984a1 1 0 0 1 1 1v5.276a1 1 0 0 1-1 1H89.361V75.79h25.86a1 1 0 0 1 1 1v5.412a1 1 0 0 1-1 1h-33.7a1 1 0 0 1-1-1v-45.6a1 1 0 0 1 1-1Zm-72.542 4.71H28.709L26.525 51.42l.04.055.093.143 8.413 13.873 8.572-13.89.094-.142c.046-.064.095-.126.146-.185l-2.156-10.963Zm-.397-20.375H29.106v15.375H41.33V19.937Zm5 2.901v12.474h14.694L46.33 22.838Zm-22.224 0L9.412 35.312h14.694V22.838Zm81.022-15.084H99.13v8.98h6v-8.98Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}
