import { z } from 'zod';

import i18n from '../../config/i18n';

export function getForgotPasswordPageSchema() {
  return z.object({
    email: z
      .string()
      .min(1, i18n.t('validation.email.required'))
      .email(i18n.t('validation.email.invalid')),
  });
}

export type IForgotPasswordPageSchema = z.infer<
  ReturnType<typeof getForgotPasswordPageSchema>
>;
