import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { UnprotectedLayout } from '../../components/UnprotectedLayout';

interface IProps {
  isLoading?: boolean;
  errorMessage?: string;
}

export function SignupConfirmationPage({
  errorMessage,
  isLoading = false,
}: IProps) {
  const { t } = useTranslation();
  return (
    <UnprotectedLayout
      title={t('pages.signupConfirmation.title', 'Confirming account')}
    >
      <Box
        marginTop={3}
        sx={{
          textAlign: 'center',
        }}
      >
        {isLoading && (
          <Box>
            <CircularProgress sx={{ margin: 3 }} />
            <Typography>
              {t(
                'pages.signupConfirmation.body',
                "Sit tight, we're verifying your account.",
              )}
            </Typography>
          </Box>
        )}
        {errorMessage && (
          <Box>
            <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
            <Typography color="error">{errorMessage}</Typography>
          </Box>
        )}
      </Box>
    </UnprotectedLayout>
  );
}
