import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Link, Stack, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Button } from '../../components/Button';
import { ControllerTextField } from '../../components/Inputs';
import { UnprotectedLayout } from '../../components/UnprotectedLayout';

import {
  getForgotPasswordPageSchema,
  IForgotPasswordPageSchema,
} from './ForgotPasswordPage.schema';

interface IProps {
  isLoading?: boolean;
  onSubmit: SubmitHandler<IForgotPasswordPageSchema>;
}

export function ForgotPasswordPage({ isLoading, onSubmit }: IProps) {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(getForgotPasswordPageSchema()),
  });

  return (
    <UnprotectedLayout
      title={t('pages.forgotPassword.title', 'Forgot password?')}
    >
      <Box
        marginX="auto"
        marginTop="20px"
        maxWidth={360}
        width="100%"
        textAlign="center"
      >
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4} marginBottom={4}>
            <Typography color="common.grey">
              {t(
                'pages.forgotPassword.body',
                'Enter the email address associated with your account',
              )}
            </Typography>
            <ControllerTextField
              control={control}
              name="email"
              placeholder={t(
                'pages.forgotPassword.emailPlaceholder',
                'Enter email address',
              )}
              fullWidth
            />
            <Button disabled={isLoading} size="large" type="submit">
              {t('pages.forgotPassword.submit', 'Send Reset')}
            </Button>
          </Stack>
        </Box>
        <Box>
          <Link component={RouterLink} to="/login">
            {t('pages.forgotPassword.remembered', 'I remember my password')}
          </Link>
        </Box>
      </Box>
    </UnprotectedLayout>
  );
}
