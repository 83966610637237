import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { redirects } from '../../config/redirects';
import { delay, getTranslatedErrorMessage } from '../../helpers';
import { useAuth } from '../../hooks';
import { useConfirmUserMutation } from '../../store/api';

import { SignupConfirmationPage } from './SignupConfirmationPage';

interface IProps {
  delayMs?: number;
}

export function SignupConfirmationPageContainer({ delayMs = 2000 }: IProps) {
  const [searchParams] = useSearchParams();
  const [mutate, { error }] = useConfirmUserMutation();
  const navigate = useNavigate();
  const { confirm } = useAuth();

  useEffect(() => {
    async function confirmUser(confirmationToken: string) {
      // Delay to allow user to see the loading indicator
      if (delayMs) {
        await delay(delayMs);
      }

      return mutate({
        token: confirmationToken,
      });
    }

    const token = searchParams.get('token');

    if (!token) {
      navigate('/');
      return;
    }

    confirmUser(token)
      .then((response) => {
        if ('data' in response && response.data.confirmUser) {
          confirm(response.data?.confirmUser);
          navigate(redirects.protectedRoute, { replace: true });
        } else if ('error' in response) {
          console.log('error', response.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [confirm, delayMs, mutate, navigate, searchParams]);

  const errorMessage = getTranslatedErrorMessage(error);

  return <SignupConfirmationPage isLoading errorMessage={errorMessage} />;
}
