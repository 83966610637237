import { Fragment, useCallback } from 'react';

import { ForgotPasswordSentModal } from '../../components/Modals';
import { useForgotPasswordMutation } from '../../store/api';

import { ForgotPasswordPage } from './ForgotPasswordPage';
import { IForgotPasswordPageSchema } from './ForgotPasswordPage.schema';

export function ForgotPasswordPageContainer() {
  const [mutate, { isLoading, isSuccess, reset, originalArgs }] =
    useForgotPasswordMutation();

  const onSubmit = useCallback(
    async (values: IForgotPasswordPageSchema) => {
      const email = values.email;

      await mutate({ email });
    },
    [mutate],
  );

  const closeModal = () => {
    reset();
  };

  return (
    <Fragment>
      <ForgotPasswordPage isLoading={isLoading} onSubmit={onSubmit} />
      <ForgotPasswordSentModal
        email={originalArgs?.email}
        onClose={closeModal}
        open={isSuccess}
      />
    </Fragment>
  );
}
