import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IProps {
  programDescription: string;
  deadlineInfo?: string;
}

export function ProgramTileInfo({ programDescription, deadlineInfo }: IProps) {
  const { t } = useTranslation();

  return (
    <Box fontSize="0.9rem">
      <Typography fontSize="inherit">{programDescription}</Typography>
      <Box mt={3}>
        <Typography fontSize="inherit" fontWeight={700}>
          {t(
            'calculator.selectProgram.tradeExecutionDeadline',
            'Trade Execution Deadline:',
          )}
        </Typography>
        <Typography fontSize="inherit">{deadlineInfo}</Typography>
      </Box>
    </Box>
  );
}
