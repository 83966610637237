import { z } from 'zod';

import i18n from '../../config/i18n';

const regexPhone =
  /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/gm;

export function getSignupPageSchema() {
  return z
    .object({
      email: z
        .string()
        .min(1, i18n.t('validation.email.required', 'email is required'))
        .email(i18n.t('validation.email.invalid', 'email is invalid')),
      phone: z
        .string()
        .min(1, i18n.t('validation.phone.required', 'phone is required'))
        .regex(
          regexPhone,
          i18n.t(
            'validation.phone.format',
            'format is invalid (e.g. +1 (555) 555-5555)',
          ),
        ),
      password: z
        .string()
        .min(1, i18n.t('validation.password.required', 'password is required'))
        .min(
          8,
          i18n.t(
            'validation.password.min',
            'password requires minimum 8 characters',
          ),
        ),
      confirmPassword: z.string(),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: i18n.t('validation.password.match', 'passwords must match'),
      path: ['confirmPassword'],
    });
}

export type ISignupPageSchema = z.infer<ReturnType<typeof getSignupPageSchema>>;
