import { Box } from '@mui/material';

import { Container } from '../../components/Container';
import { QuoteCalculatorContainer } from '../../components/QuoteCalculator';
import { QuoteStepperContainer } from '../../components/QuoteStepper';

export function CalculatorPage() {
  return (
    <Container>
      <Box mb={3}>
        <QuoteStepperContainer />
      </Box>
      <Box>
        <QuoteCalculatorContainer />
      </Box>
    </Container>
  );
}
