import { Box, Grid, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Button } from '../Button';

import { InfoBox } from './InfoBox';

interface IProps {
  city?: string | null;
  companyName?: string | null;
  country?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  state?: string | null;
  address1?: string | null;
  address2?: string | null;
  zipcode?: string | null;
}

export function AccountDetails({
  city,
  companyName,
  country,
  email,
  firstName,
  lastName,
  phone,
  state,
  address1,
  address2,
  zipcode,
}: IProps) {
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <InfoBox label={t('common.labels.firstName')}>{firstName}</InfoBox>
          <InfoBox label={t('common.labels.emailAddress')}>{email}</InfoBox>
          <InfoBox label={t('common.labels.companyName', 'Company Name')}>
            {companyName}
          </InfoBox>
          <InfoBox label={t('common.labels.country')}>{country}</InfoBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoBox label={t('common.labels.lastName')}>{lastName}</InfoBox>
          <InfoBox label={t('common.labels.phoneNumber', 'Phone Number')}>
            {phone}
          </InfoBox>
          <InfoBox label={t('common.labels.address', 'Address')}>
            {address1}
            <br />
            {address2}
            {address2 && <br />}
            {city} {state}
            {zipcode ? `, ${zipcode}` : ''}
          </InfoBox>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Link
          to="/account/change-password"
          color="primary"
          component={RouterLink}
        >
          <Button>
            {t('common.actions.changePassword', 'Change Password')}
          </Button>
        </Link>
      </Box>
    </Box>
  );
}
