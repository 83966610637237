import { Box } from '@mui/material';
import { Fragment } from 'react';
import { Outlet, OutletProps } from 'react-router-dom';

import { User } from '../../graphql/generated';
import { EmailVerifiedSuccessModalContainer } from '../Modals';

import { Footer } from './Footer';
import { Header } from './Header';
import { Logo } from './Logo';
import { Nav } from './Nav';

interface IProps {
  currentUser: User;
  onLogOutPressed: () => void;
  OutletComponent?: React.FC<OutletProps>;
}

const sidenavWidth = 188;
const headerHeight = 90;

export function MainLayout({
  onLogOutPressed,
  OutletComponent = Outlet,
  currentUser,
}: IProps) {
  return (
    <Fragment>
      <EmailVerifiedSuccessModalContainer />
      <Box
        display="grid"
        gridTemplateColumns={`${sidenavWidth}px 1fr`}
        gridTemplateRows={`${headerHeight}px 1fr`}
        gridTemplateAreas={`
          "logo header"
          "nav main"
          "footer footer"
        `}
        minHeight="100vh"
      >
        {/* Logo */}
        <Logo />
        <Header onLogOutPressed={onLogOutPressed} />
        <Nav roleType={currentUser.role} />
        {/* Main Content */}
        <Box sx={{ bgcolor: 'common.lightGrey', p: 2 }}>
          <OutletComponent />
        </Box>
        <Footer />
      </Box>
    </Fragment>
  );
}
