import { useAuth } from '../../../hooks';

import { EmailVerifiedSuccessModal } from './EmailVerifiedSuccessModal';

export function EmailVerifiedSuccessModalContainer() {
  const { hasJustConfirmed, user } = useAuth();

  return hasJustConfirmed ? (
    <EmailVerifiedSuccessModal email={user?.email} />
  ) : null;
}
