import { Box } from '@mui/material';

import { logoSvg } from '../../../helpers/publicAssetLinks';
import { Image } from '../../Image';

export function Logo() {
  return (
    <Box
      sx={{
        gridArea: 'logo',
        borderRight: '1px solid',
        borderColor: 'common.grey',
        px: 2,
        py: 3,
      }}
    >
      <Image
        alt="logo"
        src={logoSvg}
        sx={{
          maxHeight: '100%',
          maxWidth: '100%',
        }}
      />
    </Box>
  );
}
