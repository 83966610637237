import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Stack, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components/Button';
import { Container } from '../../components/Container';
import { ControllerPasswordField } from '../../components/Inputs';
import { PageTitle } from '../../components/PageTitle';

import {
  getChangePasswordPageSchema,
  IChangePasswordPageSchema,
} from './ChangePasswordPage.schema';

interface IProps {
  errorMessage?: string;
  isLoading?: boolean;
  onSubmit: SubmitHandler<IChangePasswordPageSchema>;
}

export function ChangePasswordPage({
  errorMessage,
  isLoading = false,
  onSubmit,
}: IProps) {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    resolver: zodResolver(getChangePasswordPageSchema()),
  });

  return (
    <Container minHeight={500}>
      <Box onSubmit={handleSubmit(onSubmit)} component="form" maxWidth={500}>
        <PageTitle>
          {t('pages.changePassword.title', 'Change Password')}
        </PageTitle>
        <Stack spacing={3}>
          <ControllerPasswordField
            control={control}
            name="currentPassword"
            label={t('common.labels.currentPassword')}
            fullWidth
          />
          <ControllerPasswordField
            control={control}
            name="newPassword"
            label={t('common.labels.newPassword')}
            fullWidth
          />
          <ControllerPasswordField
            control={control}
            name="confirmPassword"
            label={t('common.labels.confirmPassword')}
            fullWidth
          />
          {errorMessage && (
            <Typography marginBottom={1} color="error">
              {errorMessage}
            </Typography>
          )}
          <Button disabled={isLoading} size="large" type="submit">
            {t('common.actions.submit', 'Submit')}
          </Button>
        </Stack>
      </Box>
    </Container>
  );
}
