import { formatDuration, intervalToDuration } from 'date-fns';

export const convertDateRangeToJSON = (dateRange: Array<Date>) =>
  JSON.stringify(dateRange);

export const getTotalMonthsBetweenDateRange = (start: Date, end: Date) => {
  const interval = intervalToDuration({
    start,
    end,
  });

  const yearsToMonths = (interval.years || 0) * 12;
  const months = interval.months || 0;
  const daysToMonths = (interval.days || 0) / 30;

  return yearsToMonths + months + daysToMonths;
};

export const formatDateRange = (start: Date, end: Date) => {
  const interval = intervalToDuration({
    start,
    end,
  });

  return formatDuration(interval);
};
