import { Box, RadioGroup } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getIndustryMappings, Industry } from '../../helpers/mappings';
import { useQuoteCalculator } from '../../hooks';
import { QuoteCalculatorLayout } from '../QuoteCalculator';
import { RadioTile } from '../RadioButtons';

export function SelectIndustry() {
  const { t } = useTranslation();
  const { input } = useQuoteCalculator();

  const { control, getValues } = useForm({
    defaultValues: {
      industry: input?.industry || Industry.Agriculture,
    },
  });

  const onNextClick = () => {
    return getValues();
  };

  return (
    <QuoteCalculatorLayout
      onNext={onNextClick}
      title={t('calculator.selectIndustry.title', 'Select an Industry')}
    >
      <Box>
        <Box component="form">
          <Controller
            control={control}
            name="industry"
            render={({ field }) => (
              <RadioGroup
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                  gap: 2,
                }}
                {...field}
              >
                {getIndustryMappings().map((industry) => (
                  <RadioTile
                    key={industry.value}
                    disabled={industry.disabled}
                    id={`${industry.value}-input-radio`}
                    label={industry.label}
                    svg={industry.icon}
                    value={industry.value}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </Box>
      </Box>
    </QuoteCalculatorLayout>
  );
}
