import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { t } from 'i18next';

import { Quote } from '../../graphql/generated';

const TIME_FORMAT = 'MMM d, yyyy';

export const quoteColumns: GridColDef[] = [
  {
    align: 'center',
    field: 'clientName',
    flex: 1,
    headerAlign: 'center',
    headerName: 'Client Name',
    minWidth: 150,
  },
  {
    align: 'center',
    field: 'location',
    flex: 1,
    headerAlign: 'center',
    headerName: 'Location',
    minWidth: 150,
  },
  {
    align: 'center',
    field: 'program',
    flex: 1,
    headerAlign: 'center',
    headerName: 'Program Name',
    minWidth: 110,
  },
  {
    align: 'center',
    field: 'coveragePeriod',
    flex: 1,
    headerAlign: 'center',
    headerName: 'Risk Period',
    minWidth: 200,
    type: 'date',
  },
  {
    align: 'center',
    field: 'strikeLevel',
    flex: 1,
    headerAlign: 'center',
    headerName: 'Strike Level',
    minWidth: 150,
  },
  {
    align: 'center',
    field: 'coverageAmount',
    flex: 1,
    headerAlign: 'center',
    headerName: 'Max Payment Amount',
    minWidth: 150,
  },
  {
    align: 'center',
    field: 'premium',
    flex: 1,
    headerAlign: 'center',
    headerName: 'Premium',
    minWidth: 150,
  },
  {
    align: 'center',
    field: 'status',
    flex: 1,
    headerAlign: 'center',
    headerName: 'Status',
    minWidth: 110,
  },
  {
    align: 'center',
    field: 'createdAt',
    flex: 1,
    headerAlign: 'center',
    headerName: 'Created',
    minWidth: 130,
    type: 'date',
  },
];

function dateFormatter(date: Date) {
  return format(new Date(date), TIME_FORMAT);
}

function coveragePeriodFormatter(
  coverageStructure: Quote['coverageStructure'],
) {
  if (!coverageStructure) {
    return '';
  }

  if (coverageStructure.startDate && coverageStructure.endDate) {
    return `${dateFormatter(coverageStructure.startDate)} - ${dateFormatter(
      coverageStructure.endDate,
    )}`;
  } else if (coverageStructure.startDate && !coverageStructure.endDate) {
    return `${dateFormatter(coverageStructure.startDate)}`;
  } else if (coverageStructure.endDate && !coverageStructure.startDate) {
    return `${dateFormatter(coverageStructure.endDate)}`;
  }
}

function strikeFormatter(salesPlatform: Quote['salesPlatform']) {
  if (!salesPlatform?.relativeStrike) {
    return '';
  }

  return `${salesPlatform?.relativeStrike * 100}% of Avg`;
}

export function rowFormatter(rows: Quote[]) {
  return rows.map((row) => ({
    ...row,
    id: row._id,
    clientName: `${row.customerCounterparty?.profile?.lastName}, ${row.customerCounterparty?.profile?.firstName}`,
    location: row.coverageStructure?.location?.name,
    premium: t('fields.quotes.currency', {
      value: row.prices?.customerPremium?.vector,
    }),
    program: row.coverageStructure?.programName,
    coverageAmount: t('fields.quotes.currency', {
      value: row.coverageStructure?.limit?.vector,
    }),
    coveragePeriod: coveragePeriodFormatter(row.coverageStructure),
    createdAt: dateFormatter(row.createdAt),
    strikeLevel: strikeFormatter(row.salesPlatform),
    status: 'Saved', // TODO: status values TBD
  }));
}

export type QuoteTableRowFormat = ReturnType<typeof rowFormatter>;

export const sortByMap = {
  clientName: 'clientInfo.profile.lastName',
  coveragePeriod: 'coverageStructure.endDate',
  createdAt: 'createdAt',
  location: 'coverageStructure.location.name',
  program: 'coverageStructure.programName',
};
