import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../store/configureStore';
import { IRegistrationState, reset, setFields } from '../store/registration';

export function useRegistration() {
  const dispatch = useDispatch();

  const resetEventHandler = useCallback(() => {
    dispatch(reset());
  }, [dispatch]);

  const setFieldsEventHandler = useCallback(
    (fields: Partial<IRegistrationState>) => {
      dispatch(setFields(fields));
    },
    [dispatch],
  );

  return {
    reset: resetEventHandler,
    setFields: setFieldsEventHandler,
    data: useSelector((state: RootState) => state.registration),
  };
}
