import { Box, Grid, Typography } from '@mui/material';
import { latLng, LatLng } from 'leaflet';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MapGridDatasetOption } from '../../@types/mapping';
import { Program } from '../../helpers';
import { formatAddress } from '../../helpers/formatters';
import { useQuoteCalculator } from '../../hooks';
import { IResource } from '../../store/bingMapApi/interfaces';
import { ICalculatorInput } from '../../store/calculator';
import { MapLocationSelectorContainer } from '../Mapping/MapLocationSelector.container';
import { ReverseGeocoder } from '../Mapping/ReverseGeocoder';
import { getMapViewTypeFromProgram } from '../Mapping/constants';
import { QuoteCalculatorLayout } from '../QuoteCalculator';

const FULL_GRID_LENGTH = 12;
const PARTIAL_GRID_LENGTH = 9;
const SIDE_BAR_GRID_LENGTH = FULL_GRID_LENGTH - PARTIAL_GRID_LENGTH;

function SideBarInfo({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <Box mb={1}>
      <Typography variant="h5">{title}</Typography>
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
}

export function SelectLocation() {
  // Hooks
  const { t } = useTranslation();
  const { input } = useQuoteCalculator();

  const initialData = useRef({
    initialPosition: input?.location?.coordinates
      ? latLng(input?.location?.coordinates[0], input?.location?.coordinates[1])
      : undefined,
    initialDataset: input?.location?.dataset,
  }).current;

  // State
  const [debug, setDebug] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState<LatLng | undefined>(
    initialData?.initialPosition,
  );
  const [selectedDataset, setSelectedDataset] = useState<
    MapGridDatasetOption | undefined
  >(initialData?.initialDataset);

  const [selectedLocationInfo, setSelectedLocationInfo] = useState<
    IResource | undefined
  >();

  // Computed values
  const selectedProgram = input?.program || Program.GRP;
  const mapViewOptions = getMapViewTypeFromProgram(selectedProgram);

  const sideBarOpen = Boolean(selectedPosition);
  const formattedAddress =
    selectedLocationInfo && formatAddress(selectedLocationInfo?.address);

  // Effects

  // Add ability to toggle
  // debug via a developer browser console
  useEffect(() => {
    function toggleDebug() {
      setDebug((currentState) => !currentState);
    }

    (globalThis as any).debugMap = () => toggleDebug();

    return () => {
      (globalThis as any).debugMap = undefined;
    };
  }, []);

  // Event handlers
  const onNextHandler = useCallback(():
    | Partial<ICalculatorInput>
    | undefined => {
    if (selectedPosition && selectedDataset) {
      return {
        location: {
          coordinates: [selectedPosition.lat, selectedPosition.lng],
          name: formattedAddress || '',
          dataset: selectedDataset,
        },
      };
    }
  }, [formattedAddress, selectedDataset, selectedPosition]);

  const onPositionSelect = useCallback(
    (coordinates: LatLng, dataset: MapGridDatasetOption) => {
      setSelectedPosition(coordinates);
      setSelectedDataset(dataset);
    },
    [],
  );

  return (
    <QuoteCalculatorLayout
      onNext={onNextHandler}
      nextDisabled={!selectedPosition || !selectedDataset}
      title={t('calculator.selectLocation.title', 'Select a Location')}
    >
      <Grid container height="60vh" spacing={0}>
        <Grid item xs={sideBarOpen ? PARTIAL_GRID_LENGTH : FULL_GRID_LENGTH}>
          <MapLocationSelectorContainer
            debug={debug}
            initialDataset={selectedDataset}
            initialPosition={selectedPosition}
            mapViewOption={mapViewOptions}
            onSelected={onPositionSelect}
          />
          <ReverseGeocoder
            coordinates={selectedPosition}
            onReverseGeocoded={setSelectedLocationInfo}
          />
        </Grid>
        {sideBarOpen && (
          <Grid item xs={SIDE_BAR_GRID_LENGTH}>
            <Box
              sx={{
                backgroundColor: 'white',
                px: 3,
              }}
            >
              <SideBarInfo title="Selected Location:">
                {formattedAddress}
              </SideBarInfo>
              <SideBarInfo title="Location Grid Cell:">
                {selectedPosition?.lat.toFixed(3)},{' '}
                {selectedPosition?.lng.toFixed(3)}
              </SideBarInfo>
              <SideBarInfo title="Dataset Grid:">
                {selectedDataset?.label}
              </SideBarInfo>
            </Box>
          </Grid>
        )}
      </Grid>
    </QuoteCalculatorLayout>
  );
}
