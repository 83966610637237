import { useAuth } from '../../hooks';

import { AcccountPage } from './AccountPage';

export function AccountPageContainer() {
  const { user } = useAuth();

  if (!user) {
    throw new Error('UsageError: User is not defined');
  }

  return <AcccountPage user={user} />;
}
