import { Box, Typography } from '@mui/material';

import { useQuoteCalculator } from '../../hooks';
import { QuoteCalculatorLayout } from '../QuoteCalculator';

export function SelectClient() {
  const { input } = useQuoteCalculator();

  const selectedInput = { client: 'John Doe' };

  return (
    <QuoteCalculatorLayout input={selectedInput} title="Select a Client">
      <Box>
        <Typography>What did you select: {input?.client}</Typography>
      </Box>
    </QuoteCalculatorLayout>
  );
}
