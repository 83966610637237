import { Box } from '@mui/material';
import { LatLng } from 'leaflet';

import { LEAFLET_POSITION_CLASSES } from './constants';

interface IProps {
  currentPosition?: LatLng;
  isInsideUSA: boolean;
  isValidLocation: boolean;
  position: keyof typeof LEAFLET_POSITION_CLASSES;
  selectedPosition?: LatLng;
  zoom?: number;
}

export function MapDebugger({
  isInsideUSA,
  isValidLocation,
  currentPosition,
  selectedPosition,
  position,
  zoom,
}: IProps) {
  return (
    <Box
      bgcolor="rgba(0, 0, 0, 0.55)"
      className={LEAFLET_POSITION_CLASSES[position]}
      color="white"
      fontWeight={700}
      padding={1}
      marginTop={8}
    >
      <Box>
        Hover:{' '}
        {currentPosition &&
          `${currentPosition.lat.toFixed(
            2,
          )} (Lat), ${currentPosition.lng.toFixed(2)} (Lng)`}
      </Box>
      <Box>
        Selected:{' '}
        {selectedPosition &&
          `${selectedPosition.lat.toFixed(
            2,
          )} (Lat), ${selectedPosition.lng.toFixed(2)} (Lng)`}
      </Box>
      <Box>{isValidLocation ? 'Valid Location' : 'Invalid Location'}</Box>
      <Box>{isInsideUSA ? 'Inside USA' : 'Outside USA'}</Box>
      {typeof zoom === 'number' && <Box>Zoom: {zoom}</Box>}
    </Box>
  );
}
