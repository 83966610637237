import DateRangeIcon from '@mui/icons-material/DateRange';
import { Box, TextField, Typography } from '@mui/material';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro';
import { Control, Controller, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const TextFieldSharedInputProps = {
  endAdornment: <DateRangeIcon sx={{ opacity: 0.5 }} />,
};

interface IProps<T> {
  control: Control<T>;
  name: Path<T>;
  maxDate?: Date;
  minDate?: Date;
  onClose?: () => void;
}

export function DatePickerFromTo<T>({
  control,
  maxDate,
  minDate,
  name,
  onClose,
}: IProps<T>) {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <DateRangePicker
          maxDate={maxDate}
          minDate={minDate}
          ignoreInvalidInputs
          onClose={onClose}
          onChange={field.onChange}
          value={field.value as DateRange<Date>}
          renderInput={(startProps, endprops) => (
            <Box>
              <Box display="flex">
                <TextField
                  {...startProps}
                  label={t('common.labels.dateFrom', 'From')}
                  error={Boolean(error)}
                  InputProps={{ ...TextFieldSharedInputProps }}
                />
                <Box sx={{ mx: 4 }} />
                <TextField
                  {...endprops}
                  label={t('common.labels.dateTo', 'To')}
                  error={Boolean(error)}
                  InputProps={{ ...TextFieldSharedInputProps }}
                />
              </Box>
              <Typography color="error" mt={1} maxWidth={500}>
                {error?.message}
              </Typography>
            </Box>
          )}
        />
      )}
    />
  );
}
