import { Box, Typography } from '@mui/material';

import { HorizontalRuler } from '../HorizontalRuler';

interface IProps {
  children: React.ReactNode;
  rightSlot?: React.ReactNode;
  textAlign?: 'left' | 'center' | 'right' | 'unset' | 'initial' | 'inherit';
}

export function PageTitle({
  children,
  rightSlot,
  textAlign = 'inherit',
}: IProps) {
  return (
    <Box mb={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography textAlign={textAlign} variant="h2">
          {children}
        </Typography>
        {rightSlot}
      </Box>
      <HorizontalRuler />
    </Box>
  );
}
