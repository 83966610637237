import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Program } from '../../helpers';
import { useQuoteCalculator } from '../../hooks';
import { ICalculatorInput } from '../../store/calculator';
import { CalculatorStepValue } from '../../store/calculator/constants';
import { QuoteCalculatorLayout } from '../QuoteCalculator';
import { GRPBlockDatesSelectorContainer } from '../RiskPeriodSelectors';
import { XSRDateRangeSelectorContainer } from '../RiskPeriodSelectors/XSRDateRangeSelector';

import { IDateSelectorContainerProps } from './interfaces';

const InvalidBlock = () => (
  <div>Currently under construction. Check back later, thanks.</div>
);

type DateSelectorContainerType = (
  props: IDateSelectorContainerProps,
) => JSX.Element;

export function SelectRiskPeriod() {
  const { t } = useTranslation();
  const { input } = useQuoteCalculator();

  const [newInput, setNewInput] = useState<ICalculatorInput>({
    riskPeriod: input?.riskPeriod && [...input.riskPeriod],
  });

  const initialValues = useRef(input?.riskPeriod);

  const updateInput = useCallback(
    (riskPeriod: ICalculatorInput[CalculatorStepValue.SelectRiskPeriod]) => {
      setNewInput({
        riskPeriod: riskPeriod && [...riskPeriod],
      });
    },
    [],
  );

  const selectedProgram = input.program || 'GRP';

  let DateSelectorContainer: DateSelectorContainerType;

  switch (selectedProgram) {
    case Program.GRP:
      DateSelectorContainer = GRPBlockDatesSelectorContainer;
      break;
    case Program.XSR:
      DateSelectorContainer = XSRDateRangeSelectorContainer;
      break;
    default:
      DateSelectorContainer = InvalidBlock;
      break;
  }

  return (
    <QuoteCalculatorLayout
      input={newInput}
      nextDisabled={!newInput.riskPeriod}
      title={t('calculator.selectRiskPeriod.title', 'Select risk period')}
    >
      <DateSelectorContainer
        initialValues={initialValues.current}
        updateInput={updateInput}
      />
    </QuoteCalculatorLayout>
  );
}
