import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from '../../Button';

interface IProps {
  onLogOutPressed: () => void;
}

export function Header({ onLogOutPressed }: IProps) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        alignSelf: 'center',
        gridArea: 'header',
        justifySelf: 'flex-end',
        px: 2,
      }}
    >
      <Button size="small" variant="outlined" onClick={onLogOutPressed}>
        {t('common.actions.logout', 'Logout')}
      </Button>
    </Box>
  );
}
