import { Radio, RadioProps } from '@mui/material';
import { ReactNode } from 'react';

import { TileBlockWithLabel } from './TileBlockWithLabel';

interface IProps extends RadioProps {
  children?: ReactNode;
  label: string;
  svg: ReactNode;
}

export function RadioTile({ children, label, svg, ...radioProps }: IProps) {
  return (
    <Radio
      disableRipple
      checkedIcon={
        <TileBlockWithLabel
          checked
          htmlFor={radioProps.id}
          label={label}
          svg={svg}
        >
          {children}
        </TileBlockWithLabel>
      }
      icon={
        <TileBlockWithLabel htmlFor={radioProps.id} label={label} svg={svg}>
          {children}
        </TileBlockWithLabel>
      }
      {...radioProps}
      sx={{
        display: 'block',
        minHeight: 250,
        padding: 0,
        '&.Mui-disabled': {
          opacity: 0.4,
        },
        '&:hover': {
          bgcolor: 'transparent',
        },
        ...radioProps.sx,
      }}
    />
  );
}
