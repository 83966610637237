import {
  GetGrpDatesDocument,
  GetGrpDatesQueryVariables,
} from '../../../graphql/generated';

export const getGRPDatesEndpoint = {
  query: (variables: GetGrpDatesQueryVariables) => ({
    document: GetGrpDatesDocument,
    variables,
  }),
};
