import { IDateSelectorContainerProps } from '../../QuoteCalculatorScreens/interfaces';

import { XSRDateRangeSelector } from './XSRDateRangeSelector';

export function XSRDateRangeSelectorContainer({
  initialValues,
  updateInput,
}: IDateSelectorContainerProps) {
  return (
    <XSRDateRangeSelector
      initialValues={initialValues}
      updateInput={updateInput}
    />
  );
}
