import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AccountDetails } from '../../components/AccountDetails';
import { Container } from '../../components/Container';
import { PageTitle } from '../../components/PageTitle';
import { User, UserProfile } from '../../graphql/generated';

interface IProps {
  user: User;
}

export function AcccountPage({ user }: IProps) {
  const { t } = useTranslation();

  const { email, profile = {} } = user;
  const {
    address1,
    address2,
    city,
    companyName,
    country,
    firstName,
    lastName,
    phone,
    state,
    zipcode,
  } = profile as UserProfile;

  return (
    <Container minHeight={500}>
      <Box maxWidth={800}>
        <PageTitle>{t('pages.accountdetails.title')}</PageTitle>
        <AccountDetails
          city={city}
          companyName={companyName}
          country={country}
          email={email}
          firstName={firstName}
          lastName={lastName}
          phone={phone}
          state={state}
          address1={address1}
          address2={address2}
          zipcode={zipcode}
        />
      </Box>
    </Container>
  );
}
