import { Box, Step, StepButton, Stepper, Typography } from '@mui/material';
import { format } from 'date-fns';

import { ICalculatorInput, ICalculatorStep } from '../../store/calculator';

export const TIME_FORMAT = 'MMM d, yyyy';

export const displayFormattedDates = (dates: string[]) => {
  return `(${dates[0]} - ${dates[1]})`;
};

interface IProps {
  activeStepIndex: number;
  completedSteps: Array<boolean>;
  onStepClick: (step: number) => void;
  input: ICalculatorInput;
  steps: ICalculatorStep[];
}

export function QuoteStepper({
  activeStepIndex,
  completedSteps,
  input,
  onStepClick,
  steps,
}: IProps) {
  return (
    <Box>
      <Stepper nonLinear alternativeLabel activeStep={activeStepIndex}>
        {steps.map(({ label }, index) => {
          const previousStep = steps[index - 1];
          const previousInput = previousStep ? input[previousStep.value] : true;
          const currentInput = input[steps[index].value];
          const isDisabled = !previousInput;
          let formattedDates: string[] | undefined;

          if (Array.isArray(currentInput)) {
            const [startDate, endDate, deadlineDate = ''] = currentInput;
            formattedDates = [
              format(new Date(startDate), TIME_FORMAT),
              format(new Date(endDate), TIME_FORMAT),
              format(new Date(deadlineDate), TIME_FORMAT),
            ];
          }

          return (
            <Step key={label} completed={completedSteps[index]}>
              <StepButton
                color="primary"
                onClick={() => onStepClick(index)}
                disabled={isDisabled}
                sx={{
                  opacity: isDisabled ? 0.5 : 1,
                }}
              >
                {label}
                {label === 'Risk Period' && formattedDates && (
                  <Typography display="block" variant="caption">
                    {displayFormattedDates(formattedDates)}
                  </Typography>
                )}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
