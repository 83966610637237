import { z } from 'zod';

import i18n from '../../config/i18n';

export function getResetPasswordPageSchema() {
  return z
    .object({
      password: z
        .string()
        .min(1, i18n.t('validation.password.required', 'password is required'))
        .min(
          8,
          i18n.t(
            'validation.password.min',
            'password requires minimum 8 characters',
          ),
        ),
      confirmPassword: z.string(),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: i18n.t('validation.password.match', 'passwords must match'),
      path: ['confirmPassword'],
    });
}

export type IResetPasswordPageSchema = z.infer<
  ReturnType<typeof getResetPasswordPageSchema>
>;
