import { RouteObject } from 'react-router-dom';

import { AccountPageContainer } from '../../pages/AccountPage';
import { ApplyPage } from '../../pages/ApplyPage';
import { CalculatorPage } from '../../pages/CalculatorPage';
import { ChangePasswordPageContainer } from '../../pages/ChangePasswordPage/ChangePasswordPage.container';
import { ContractsPage } from '../../pages/ContractsPage';
import { ForgotPasswordPageContainer } from '../../pages/ForgotPasswordPage/ForgotPasswordPage.container';
import { HelpPage } from '../../pages/HelpPage';
import { LoginPageContainer } from '../../pages/LoginPage';
import { NotFoundPage } from '../../pages/NotFoundPage';
import { NoticesPage } from '../../pages/NoticesPage';
import { QuotesPageContainer } from '../../pages/QuotesPage/QuotesPage.container';
import { ResetPasswordPageContainer } from '../../pages/ResetPasswordPage';
import { SignupAccountCreatedPageContainer } from '../../pages/SignupAccountCreatedPage';
import { SignupAccountDetailsPageContainer } from '../../pages/SignupAccountDetailsPage';
import { SignupConfirmationPageContainer } from '../../pages/SignupConfirmationPage/SignupConfirmationPage.container';
import { SignupPageContainer } from '../../pages/SignupPage';
import { MainLayoutContainer } from '../MainLayout';

import { ProtectedRouteCheck } from './ProtectedRouteCheck';
import { UnprotectedRouteCheck } from './UnprotectedRouteCheck';

export const routes: RouteObject[] = [
  {
    // Unprotected Routes
    element: <UnprotectedRouteCheck />,
    children: [
      {
        index: true,
        path: '/',
        element: <SignupPageContainer />,
      },
      {
        path: '/login',
        element: <LoginPageContainer />,
      },
      {
        path: '/register',
        element: <SignupAccountDetailsPageContainer />,
      },
      {
        path: '/register/success',
        element: <SignupAccountCreatedPageContainer />,
      },
      {
        path: '/forgot',
        element: <ForgotPasswordPageContainer />,
      },
      {
        path: '/reset',
        element: <ResetPasswordPageContainer />,
      },
      {
        path: '/confirm',
        element: <SignupConfirmationPageContainer />,
      },
    ],
  },
  {
    // Protected Routes
    element: <ProtectedRouteCheck />,
    children: [
      {
        element: <MainLayoutContainer />,
        children: [
          {
            path: '/account',
            element: <AccountPageContainer />,
          },
          {
            path: '/apply',
            element: <ApplyPage />,
          },
          {
            path: '/calculator',
            element: <CalculatorPage />,
          },
          {
            path: '/contact',
            element: <HelpPage />,
          },
          {
            path: '/help',
            element: <HelpPage />,
          },
          {
            path: '/contracts',
            element: <ContractsPage />,
          },
          {
            path: '/notices',
            element: <NoticesPage />,
          },
          {
            path: '/account/change-password',
            element: <ChangePasswordPageContainer />,
          },
          {
            path: '/quotes',
            element: <QuotesPageContainer />,
          },
        ],
      },
    ],
  },
  {
    // Not Found
    path: '*',
    element: <NotFoundPage />,
  },
];
