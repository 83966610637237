import { LatLng } from 'leaflet';
import debounce from 'lodash/debounce';
import { useEffect, useMemo } from 'react';

import { useLazyReverseGeocoderQuery } from '../../store/bingMapApi';

interface IProps {
  coordinates?: LatLng;
  onReverseGeocoded: (result: any) => void;
}

export function ReverseGeocoder({ coordinates, onReverseGeocoded }: IProps) {
  const [reverseGeocode] = useLazyReverseGeocoderQuery();

  const debouncedReverseGeocode = useMemo(
    () =>
      debounce((arg: string) => reverseGeocode(arg, true), 200, {
        leading: true,
        trailing: true,
      }),
    [reverseGeocode],
  );

  // Use effects
  useEffect(() => {
    if (coordinates) {
      debouncedReverseGeocode(String([coordinates.lat, coordinates.lng]))?.then(
        (results) => {
          const resource = results.data?.resourceSets?.[0]?.resources[0];
          onReverseGeocoded(resource);
        },
      );
    }
  }, [coordinates, debouncedReverseGeocode, onReverseGeocoded]);

  return null;
}
