import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface IProps {
  checked?: boolean;
  children?: ReactNode;
  htmlFor?: string | unknown;
  label: string;
  svg: ReactNode;
}

export function TileBlockWithLabel({
  checked,
  children,
  htmlFor,
  label,
  svg,
}: IProps) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        bgcolor: checked ? 'primary.main' : 'transparent',
        borderColor: 'primary.main',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderWidth: 1,
        color: checked ? 'common.white' : 'common.darkGrey',
        display: 'flex',
        flexBasis: '25%',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        padding: 4,
        textAlign: 'center',
        svg: {
          color: checked ? 'white' : 'common.grey',
          opacity: checked ? 1 : 0.6,
        },
      }}
    >
      <Box height={102} width={140}>
        {svg}
      </Box>
      <Typography
        component="label"
        color={checked ? 'common.white' : 'primary.main'}
        fontWeight={checked ? 700 : 'inherit'}
        htmlFor={String(htmlFor)}
        my={1}
      >
        {label}
      </Typography>
      {children}
    </Box>
  );
}
