import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CalculatorStepValue } from './constants';
import {
  ICalculatorInput,
  ICalculatorState,
  ICalculatorStep,
} from './interfaces';

export const defaultSteps: ICalculatorStep[] = [
  {
    label: 'Industry',
    value: CalculatorStepValue.SelectIndustry,
  },
  {
    label: 'Program',
    value: CalculatorStepValue.SelectProgram,
  },
  {
    label: 'Risk Period',
    value: CalculatorStepValue.SelectRiskPeriod,
  },
  {
    label: 'Location',
    value: CalculatorStepValue.SelectLocation,
  },
  {
    label: 'Client',
    value: CalculatorStepValue.SelectClient,
  },
  {
    label: 'Options Term',
    value: CalculatorStepValue.SelectOptionsTerm,
  },
  {
    label: 'Review',
    value: CalculatorStepValue.Review,
  },
];

export const initialCalculatorState: ICalculatorState = {
  activeStepIndex: 0,
  completedSteps: [],
  input: {},
  steps: [...defaultSteps],
};

export const calculatorSlice = createSlice({
  name: 'calculator',
  initialState: initialCalculatorState,
  reducers: {
    completeStep: (state, action: PayloadAction<ICalculatorInput>) => {
      const { payload } = action;
      state.completedSteps[state.activeStepIndex] = true;
      state.input = {
        ...state.input,
        ...payload,
      };

      // If the last step is completed, stay on the last step
      const lastStepIndex = state.steps.length - 1;
      const isLastStep = state.activeStepIndex === lastStepIndex;
      state.activeStepIndex = isLastStep
        ? lastStepIndex
        : state.activeStepIndex + 1;
    },
    nextStep: (state) => {
      const isLastStep = state.activeStepIndex === state.steps.length - 1;

      // If the last step is completed, loop to the first step
      state.activeStepIndex = isLastStep ? 0 : state.activeStepIndex + 1;
    },
    previousStep: (state) => {
      state.activeStepIndex =
        state.activeStepIndex === 0 ? 0 : state.activeStepIndex - 1;
    },
    reset: () => ({ ...initialCalculatorState }),
    setInput: (state, action: PayloadAction<ICalculatorInput>) => {
      const { payload } = action;
      state.input = {
        ...state.input,
        ...payload,
      };
    },
    setStep: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.activeStepIndex = payload;
    },
  },
});

export const {
  completeStep,
  nextStep,
  previousStep,
  reset,
  setInput,
  setStep,
} = calculatorSlice.actions;
