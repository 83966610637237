import { latLng, LatLng, popup } from 'leaflet';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Rectangle, Circle, LayerGroup, useMapEvents } from 'react-leaflet';

import { DebugGrid } from './DebugGrid';
import {
  convertLatLngToGridCoordinates,
  createRectangleBounds,
} from './helpers';

interface IProps {
  debug?: boolean;
  isValidLocation: boolean;
  onSelect: (position: LatLng) => void;
  position: LatLng;
  selectedPosition: LatLng | undefined;
  step: number;
  offset?: number;
}

export function MapGridSelector({
  debug = true,
  isValidLocation,
  onSelect,
  position,
  selectedPosition,
  step,
  offset = 0,
}: IProps) {
  const { t } = useTranslation();

  const map = useMapEvents({
    click: (event) => {
      if (isValidLocation) {
        const [clickedX, clickedY] = convertLatLngToGridCoordinates(
          [event.latlng.lat, event.latlng.lng],
          step,
          offset,
        );

        const latSelectedCenter = clickedY * step + offset;
        const lngSelectedCenter = clickedX * step + offset;

        onSelect(latLng(latSelectedCenter, lngSelectedCenter));
      } else {
        console.log(event);
        const invalidPopup = popup()
          .setContent(
            t(
              'map.error.invalidLocation',
              'Prequoted program not available at this location.',
            ),
          )
          .setLatLng(event.latlng);
        map?.openPopup(invalidPopup);
      }
    },
  });

  /**
   * Create a rectangle around the current position.
   */
  const { latCenter, lngCenter, mouseHoverBounds } = useMemo(() => {
    const [XGridCenter, YGridCenter] = convertLatLngToGridCoordinates(
      [position.lat, position.lng],
      step,
      offset,
    );

    const latCenter = YGridCenter * step + offset;
    const lngCenter = XGridCenter * step + offset;

    const mouseHoverBounds = createRectangleBounds(
      [XGridCenter, YGridCenter],
      step,
      offset,
    );

    return {
      mouseHoverBounds,
      latCenter,
      lngCenter,
    };
  }, [offset, position.lat, position.lng, step]);

  /**
   * Create a rectangle around the selected position.
   */
  const selectedBounds = useMemo(() => {
    return selectedPosition
      ? createRectangleBounds(
          convertLatLngToGridCoordinates(
            [selectedPosition.lat, selectedPosition.lng],
            step,
            offset,
          ),
          step,
          offset,
        )
      : null;
  }, [offset, selectedPosition, step]);

  const currentZoom = map.getZoom();
  const isDisplayed = currentZoom >= 2;

  return (
    <LayerGroup>
      {selectedBounds && (
        <Rectangle
          bounds={selectedBounds}
          interactive={false}
          pathOptions={{ color: 'green' }}
        />
      )}
      {isValidLocation && (
        <Rectangle
          bounds={mouseHoverBounds}
          interactive={false}
          pathOptions={{
            color: '#1E90FF',
            fillColor: '#1E90FF',
            fillOpacity: 0.21,
            opacity: 1,
          }}
        />
      )}
      {debug && isValidLocation && (
        <Circle
          interactive={false}
          center={[latCenter, lngCenter]}
          radius={100}
          pathOptions={{ fillOpacity: 1, fillColor: 'red', stroke: false }}
        />
      )}
      {debug && isDisplayed && (
        <DebugGrid offset={offset} step={step} zoom={currentZoom} />
      )}
    </LayerGroup>
  );
}
