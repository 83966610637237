import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Stack, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components/Button';
import { ControllerPasswordField } from '../../components/Inputs';
import { UnprotectedLayout } from '../../components/UnprotectedLayout';

import {
  getResetPasswordPageSchema,
  IResetPasswordPageSchema,
} from './ResetPasswordPage.schema';

interface IProps {
  errorMessage?: string;
  isLoading?: boolean;
  onSubmit: SubmitHandler<IResetPasswordPageSchema>;
}

export function PasswordResetPage({
  errorMessage,
  isLoading,
  onSubmit,
}: IProps) {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: zodResolver(getResetPasswordPageSchema()),
  });

  return (
    <UnprotectedLayout title={t('pages.resetPassword.title', 'Reset Password')}>
      <Box
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        marginX="auto"
        marginTop="20px"
        maxWidth={360}
        width="100%"
      >
        <Stack spacing={3}>
          <ControllerPasswordField
            control={control}
            name="password"
            label={t('common.labels.password')}
            fullWidth
          />
          <ControllerPasswordField
            control={control}
            name="confirmPassword"
            label={t('common.labels.confirmPassword')}
            fullWidth
          />
          <Button disabled={isLoading} size="large" type="submit">
            {t('common.actions.submit', 'Submit')}
          </Button>
          {errorMessage && (
            <Typography marginBottom={1} color="error">
              {errorMessage}
            </Typography>
          )}
        </Stack>
      </Box>
    </UnprotectedLayout>
  );
}
