import { Box, Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  backgroundCirclesImage,
  backgroundImage,
  logoSvg,
  privacyPolicyPdf,
  termOfUsePdf,
} from '../../helpers/publicAssetLinks';
import { Image } from '../Image';

interface IProps {
  children: React.ReactNode;
  title?: string;
}

export function UnprotectedLayout({ children, title }: IProps) {
  const { t } = useTranslation();

  return (
    <Grid
      container
      flexDirection="row"
      flexWrap="nowrap"
      height="100%"
      justifyContent="flex-end"
      minHeight="100vh"
    >
      <Grid
        item
        alignItems="center"
        display={{ xs: 'none', md: 'flex' }}
        flexGrow={1}
        flexShrink={1}
        justifyContent="center"
        position="relative"
        zIndex={0}
      >
        <Image
          alt="background image"
          src={backgroundImage}
          sx={{
            bottom: 0,
            height: '100%',
            left: 0,
            objectFit: 'cover',
            objectPosition: 'top',
            position: 'absolute',
            right: 0,
            top: 0,
            width: '100%',
            zIndex: -2,
          }}
        />
        <Image
          alt="background circles"
          src={backgroundCirclesImage}
          sx={{
            bottom: 0,
            height: '100%',
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: -1,
          }}
        />
        <Box
          p={8}
          sx={{
            alignSelf: 'flex-start',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            color="white"
            fontSize={['2rem', '4rem', '6rem']}
            fontWeight={700}
            textTransform="capitalize"
            variant="h1"
          >
            {t(
              'pages.signup.catchPhrase',
              'Coverage has never been this simple',
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        alignContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
        flexShrink={0}
        paddingY={2}
        paddingX={{
          xs: 4,
          md: 8,
        }}
        width={{
          xs: '100%',
          md: '685px',
        }}
      >
        <Box marginY={3}>
          <img alt="Abrol Logo" src={logoSvg} />
        </Box>
        <Typography textAlign="center" variant="h2">
          {title}
        </Typography>
        {children}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: 8,
          }}
        >
          <Typography fontSize="0.85rem" color="common.grey">
            {t(
              'layouts.unprotected.disclaimer',
              'Arbol is not a futures or a cleared swaps market.',
            )}
          </Typography>
          <Link
            href={privacyPolicyPdf}
            target="_blank"
            rel="noopener noreferrer"
            color="common.grey"
            fontSize="0.85rem"
            marginTop={4}
            underline="none"
          >
            {t('common.labels.privacyPolicy', 'Privacy Policy')}
          </Link>
          <Link
            href={termOfUsePdf}
            target="_blank"
            rel="noopener noreferrer"
            color="common.grey"
            fontSize="0.85rem"
            underline="none"
          >
            {t('common.labels.termOfUse', 'Terms of use')}
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
}
