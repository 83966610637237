import { Fragment, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { PasswordResetSuccessModal } from '../../components/Modals';
import { getTranslatedErrorMessage } from '../../helpers';
import { useAuth } from '../../hooks';
import { useResetPasswordMutation } from '../../store/api';

import { PasswordResetPage } from './ResetPasswordPage';
import { IResetPasswordPageSchema } from './ResetPasswordPage.schema';

export function ResetPasswordPageContainer() {
  const [searchParams] = useSearchParams();
  const [mutate, { error, data, isLoading, isSuccess }] =
    useResetPasswordMutation();

  const { login } = useAuth();

  const onSubmit = useCallback(
    async (values: IResetPasswordPageSchema) => {
      const { password } = values;
      const token = searchParams.get('token');

      if (!token) {
        return;
      }

      await mutate({ password, token });
    },
    [mutate, searchParams],
  );

  const onClose = useCallback(() => {
    const payload = data?.resetPassword;

    if (payload) {
      login(payload);
    }
  }, [data, login]);

  const errorMessage = getTranslatedErrorMessage(error);

  return (
    <Fragment>
      <PasswordResetPage
        onSubmit={onSubmit}
        isLoading={isLoading}
        errorMessage={errorMessage}
      />
      <PasswordResetSuccessModal open={isSuccess} onClose={onClose} />
    </Fragment>
  );
}
