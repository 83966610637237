import { LatLng } from 'leaflet';
import { useTranslation } from 'react-i18next';

import { MapGridDatasetOption } from '../../@types/mapping';
import {
  useGetCountriesGeoJSONQuery,
  useGetStatesGeoJSONQuery,
} from '../../store/arbolGeojson';
import { InnerContainerLoader } from '../Loaders';

import { MapLocationSelector } from './MapLocationSelector';
import { getMapViewTypeFromProgram, MapViewOption } from './constants';

interface IProps {
  debug?: boolean;
  initialDataset?: MapGridDatasetOption;
  initialPosition?: LatLng;
  mapViewOption?: MapViewOption;
  onSelected?: (coordinates: LatLng, dataset: MapGridDatasetOption) => void;
}

export function MapLocationSelectorContainer({
  debug = false,
  initialDataset,
  initialPosition,
  mapViewOption = getMapViewTypeFromProgram('GRP'),
  onSelected,
}: IProps) {
  const { t } = useTranslation();

  const {
    data: countriesGeoJSON,
    isLoading: isLoadingCountriesGeoJSON,
    // isError: isErrorCountriesGeoJSONN,
  } = useGetCountriesGeoJSONQuery(null);
  const {
    data: usaGeoJSON,
    isLoading: isLoadingStatesGeoJSON,
    // isError: isErrorStatesGeoJSON,
  } = useGetStatesGeoJSONQuery(null);

  if (
    !countriesGeoJSON ||
    !usaGeoJSON ||
    isLoadingCountriesGeoJSON ||
    isLoadingStatesGeoJSON
  ) {
    return (
      <InnerContainerLoader
        message={t('common.actions.loading', 'Loading ...')}
      />
    );
  }

  return (
    <MapLocationSelector
      countriesGeoJSON={countriesGeoJSON}
      debug={debug}
      initialDataset={initialDataset}
      initialPosition={initialPosition}
      mapViewOption={mapViewOption}
      onSelected={onSelected}
      usaGeoJSON={usaGeoJSON}
    />
  );
}
