import { LatLng, LeafletEvent, LeafletMouseEvent } from 'leaflet';
import { useCallback } from 'react';
import { useMapEvent } from 'react-leaflet';

interface IProps {
  onPositionChange: (position: LatLng) => void;
  onZoomChange: (zoom: number) => void;
}

export function MapOnChangesTracking({
  onPositionChange,
  onZoomChange,
}: IProps) {
  const onMouseMove = useCallback(
    (event: LeafletMouseEvent) => {
      onPositionChange(event.latlng);
    },
    [onPositionChange],
  );

  const onZoom = useCallback(
    (event: LeafletEvent) => {
      onZoomChange(event.target._zoom);
    },
    [onZoomChange],
  );

  useMapEvent('mousemove', onMouseMove);
  useMapEvent('zoom', onZoom);

  return null;
}
