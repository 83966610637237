import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { theme } from '../../../config';
import { ModalBase } from '../ModalBase';

interface IProps {
  email?: string;
  open?: boolean;
  onClose: () => void;
}

export function ForgotPasswordSentModal({
  email,
  open = false,
  onClose,
}: IProps) {
  const { t } = useTranslation();

  return (
    <ModalBase
      open={open}
      onClose={onClose}
      title={t('modals.forgotPasswordSent.title', 'Reset email sent!')}
    >
      <Box p={2}>
        <Typography>
          <Trans i18nKey="modals.forgotPasswordSent.message">
            Please check{' '}
            <span style={{ color: theme.palette.primary.main }}>
              {{ email }}
            </span>{' '}
            for a password reset link.
          </Trans>
        </Typography>
      </Box>
    </ModalBase>
  );
}
