import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Link, Stack } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Button } from '../../components/Button';
import {
  ControllerPasswordField,
  ControllerTextField,
} from '../../components/Inputs';
import { UnprotectedLayout } from '../../components/UnprotectedLayout';

import { ISignupPageSchema, getSignupPageSchema } from './SignupPage.schema';

interface IProps {
  initialValues?: Partial<ISignupPageSchema>;
  onSubmit: SubmitHandler<ISignupPageSchema>;
}

export function SignupPage({ initialValues, onSubmit }: IProps) {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm<ISignupPageSchema>({
    defaultValues: {
      email: initialValues?.email || '',
      phone: initialValues?.phone || '',
      password: initialValues?.password || '',
      confirmPassword: initialValues?.password || '',
    },
    resolver: zodResolver(getSignupPageSchema()),
  });

  return (
    <UnprotectedLayout title={t('pages.signup.title', 'Create an account')}>
      <Box
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        marginX="auto"
        marginTop="20px"
        maxWidth={360}
        width="100%"
      >
        <Stack spacing={1}>
          <ControllerTextField
            control={control}
            name="email"
            label={t('common.labels.email', 'Email')}
            fullWidth
          />
          <ControllerTextField
            control={control}
            name="phone"
            label={t('common.labels.phone', 'Phone')}
            fullWidth
          />
          <ControllerPasswordField
            control={control}
            name="password"
            label={t('common.labels.password', 'Password')}
            type="password"
            fullWidth
          />
          <ControllerPasswordField
            control={control}
            name="confirmPassword"
            label={t('common.labels.confirmPassword', 'Repeat Password')}
            type="password"
            fullWidth
          />
        </Stack>
        <Box marginY={3}>
          <Button fullWidth size="large" type="submit">
            {t('pages.signup.signupButton', 'Sign up')}
          </Button>
        </Box>
      </Box>
      <Link to="/login" color="primary" component={RouterLink}>
        {t('pages.signup.haveAccount', 'Have an account already?')}
      </Link>
    </UnprotectedLayout>
  );
}
